import React from "react"
import Pagination from "rc-pagination"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import EllipsisLoader from "../../../../template/EllipsisLoader"
import TableHead from "./TableHead"
import TableBody from "./TableBody"
import TableNoData from "../../../../template/TableNoData"

const MainTable = () => {
    const { perPage, loading, items, currentPage, setCurrentPage, totalItems } =
        useCampaigns()

    return (
        <div className="w-100">
            <div className="w-100 bg-light">
                <table className="table align-middle rounded-top">
                    <TableHead />

                    {loading && (
                        <tr style={{ height: `${perPage * 50}px` }}>
                            <td colSpan={7}>
                                <EllipsisLoader dark />
                            </td>
                        </tr>
                    )}

                    {!loading && (
                        <tbody>
                            <TableNoData items={items} colspan={7} />
                            <TableBody />
                        </tbody>
                    )}
                </table>
            </div>

            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default MainTable
