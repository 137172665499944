import React from "react"
import { useNavigate } from "react-router"
import Layout from "../components/template/Layout"
import { clearUserToken } from "../functions/clearUserToken"
import { AppRoutes } from "../utilities/AppRoutes"
import Button from "../components/template/Button"
import { toast } from "react-toastify"
import { useApp } from "../providers/AppProvider"
import { Trans, useTranslation } from "react-i18next"

const LogoutActions = () => {
    // States and Hooks
    const { t } = useTranslation()
    const navigator = useNavigate()
    const { setCurrentAdmin } = useApp()

    // Methods
    const cancel = () => {
        navigator(AppRoutes.home)
    }

    const exit = () => {
        clearUserToken()
        setCurrentAdmin(null)
        navigator(AppRoutes.login)
        toast.success(t("Logged Out Successfully"))
    }

    return (
        <Layout>
            <div className="body-height d-flex align-items-center justify-content-center">
                <div className="h3 fw-500">
                    <div>
                        <Trans>Are you sure you want to exit?</Trans>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                        <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={cancel}
                        >
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button
                            variant="primary"
                            className="w-100 text-white"
                            onClick={exit}
                        >
                            <Trans>Exit</Trans>
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LogoutActions
