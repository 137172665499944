import React from "react"
import { useCampaignOwnerUsers } from "./CampaignOwnerUsersProvider"
import { Icons } from "../../../../utilities/Icons"
import EllipsisLoader from "../../../template/EllipsisLoader"
import Input from "../../../template/Input"
import { Trans } from "react-i18next"

const CreateCampaignOwnerUSerForm = () => {
    const { loading, isCreateMode, createCampaignOwnerUserFormik } =
        useCampaignOwnerUsers()

    return (
        <form
            className="d-flex flex-column flex-lg-row lg:align-items-center w-100 gap-2"
            onSubmit={createCampaignOwnerUserFormik.handleSubmit}
        >
            <div className="flex-fill">
                <Input
                    id="email"
                    name="email"
                    type="text"
                    disabled={loading}
                    onChange={createCampaignOwnerUserFormik.handleChange}
                    value={createCampaignOwnerUserFormik.values.email}
                    className="form-control rounded-0"
                    placeholder="Email"
                />
            </div>
            <div className="flex-fill">
                <Input
                    id="password"
                    name="password"
                    type="password"
                    disabled={loading}
                    onChange={createCampaignOwnerUserFormik.handleChange}
                    value={createCampaignOwnerUserFormik.values.password}
                    className="form-control rounded-0"
                    placeholder="Password"
                />
            </div>

            <div className="">
                <button
                    className="btn btn-sm btn-primary d-flex align-items-center justify-content-center w-100"
                    type="submit"
                    disabled={loading}
                >
                    {loading && isCreateMode ? (
                        <EllipsisLoader dark />
                    ) : (
                        <>
                            <div className="small-icon">{Icons.add()}</div>
                            <div className="text-nowrap">
                                <Trans>Add User</Trans>
                            </div>
                        </>
                    )}
                </button>
            </div>
        </form>
    )
}

export default CreateCampaignOwnerUSerForm
