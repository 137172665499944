import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useAdmins } from "../../../providers/AdminsProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import { AdminsCreateFormSchema } from "../../../utilities/FormValidations"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import Input from "../../template/Input"
import { Trans, useTranslation } from "react-i18next"

const CreateAdminModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { admin } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isCreateModalOpen: isOpen,
        setIsCreateModalOpen: setIsOpen,
        initialize,
    } = useAdmins()

    const createAdminFormik = useFormik({
        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
        },
        onSubmit: async values => {
            setError("")
            setLoading(true)
            const validationResponse = await AdminsCreateFormSchema.validate({
                email: values.email,
                password: values.password,
                confirmPassword: values.confirmPassword,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await admin.register({
                    email: values.email,
                    password: values.password,
                })
                if (error) setError(error)
                if (data) {
                    closeModal()
                    await initialize()
                    toast.success(t("Admin created successfully"))
                }
            }
            setLoading(false)
        },
    })

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={createAdminFormik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Create Admin</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Email</Trans>:
                        </label>
                        <Input
                            id="email"
                            name="email"
                            type="text"
                            disabled={loading}
                            onChange={createAdminFormik.handleChange}
                            value={createAdminFormik.values.email}
                            className="form-control rounded-0"
                            placeholder={t("Email")}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Password</Trans>:
                        </label>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            disabled={loading}
                            onChange={createAdminFormik.handleChange}
                            value={createAdminFormik.values.password}
                            className="form-control rounded-0"
                            placeholder={t("Password")}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Confirm Password</Trans>:
                        </label>
                        <Input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            disabled={loading}
                            onChange={createAdminFormik.handleChange}
                            value={createAdminFormik.values.confirmPassword}
                            className="form-control rounded-0"
                            placeholder={t("Confirm Password")}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            type="button"
                            onClick={closeModal}
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Add</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateAdminModal
