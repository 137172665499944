import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { Trans } from "react-i18next"

const FormButtons = () => {
    const { closeCreateModal: closeModal } = useCampaigns()

    return (
        <div className="row m-0 justify-content-end">
            <div className="col-12 col-lg-3 p-0 mb-2 mb-lg-0 ps-lg-1 pe-lg-1">
                <button
                    className="btn btn-outline-dark fw-600 rounded-0 w-100"
                    onClick={closeModal}
                    type="button"
                >
                    <Trans>Cancel</Trans>
                </button>
            </div>

            <div className="col-12 col-lg-3 p-0 ps-lg-1 pe-lg-0">
                <button
                    type="submit"
                    className="btn btn-outline-primary fw-600 rounded-0 w-100"
                >
                    <Trans>Next</Trans>
                </button>
            </div>
        </div>
    )
}

export default FormButtons
