import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { useFilters } from "../../../providers/FiltersProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import { FiltersCreateSchema } from "../../../utilities/FormValidations"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import { useTranslation, Trans } from "react-i18next"
import Input from "../../template/Input"

const CreateFilterModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { filter } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isCreateModalOpen: isOpen,
        setIsCreateModalOpen: setIsOpen,
        initialize,
    } = useFilters()

    const createFilterFormik = useFormik({
        initialValues: {
            name: "",
            order: undefined,
            isActive: false,
        },
        onSubmit: async values => {
            setError("")
            setLoading(true)
            const validationResponse = await FiltersCreateSchema.validate({
                name: values.name,
                order: values.order,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await filter.create({
                    name: values.name,
                    order: values.order,
                    isActive: values.isActive,
                })
                if (error) setError(error)
                if (data) {
                    closeModal()
                    await initialize()
                    toast.success(t("Filter created successfully"))
                }
            }
            setLoading(false)
        },
    })

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={createFilterFormik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Create Filter</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Name</Trans>:
                        </label>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            disabled={loading}
                            onChange={createFilterFormik.handleChange}
                            value={createFilterFormik.values.name}
                            className="form-control rounded-0"
                            placeholder="Name"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Order</Trans>:
                        </label>
                        <Input
                            id="order"
                            name="order"
                            type="number"
                            disabled={loading}
                            onChange={createFilterFormik.handleChange}
                            value={createFilterFormik.values.order}
                            className="form-control rounded-0"
                            placeholder="Order"
                        />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <label
                            className="fw-500 no-select"
                            htmlFor="status-checkbox"
                        >
                            <Trans>Is Active</Trans>:
                        </label>
                        <Form.Check
                            className="custom-checkbox"
                            type="checkbox"
                            id="isActive"
                            name="isActive"
                            disabled={loading}
                            onChange={createFilterFormik.handleChange}
                            value={createFilterFormik.values.isActive}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                            type="button"
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Add</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateFilterModal
