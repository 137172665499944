import React from "react"
import Pagination from "rc-pagination"
import { useCampaignOwners } from "../../../providers/CampaignOwnersProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import { Icons } from "../../../utilities/Icons"
import ItemStatusSwitch from "../../template/ItemStatusSwitch"
import NoData from "../../template/NoData"
import TableButton from "../../template/TableButton"
import { Trans } from "react-i18next"

const CampaignOwnersTable = () => {
    const {
        perPage,
        loading,
        items,
        currentPage,
        setCurrentPage,
        totalItems,
        setSelectedItem,
        setIsEditModalOpen,
        setIsUsersModalOpen,
        setIsArchiveModalOpen,
        activateCampaignOwner,
        disableCampaignOwner,
    } = useCampaignOwners()

    const openEditModal = () => setIsEditModalOpen(true)
    const openUsersModal = () => setIsUsersModalOpen(true)
    const openArchiveModal = () => setIsArchiveModalOpen(true)

    return (
        <div className="w-100">
            <div className="w-100 bg-light">
                <table className="table align-middle overflow-hidden rounded-top">
                    <thead className="bg-white table-primary text-white">
                        <tr>
                            <th>
                                <Trans>Organization</Trans>
                            </th>
                            <th>
                                <Trans>Status</Trans>
                            </th>
                            <th className="text-end">
                                <Trans>Actions</Trans>
                            </th>
                        </tr>
                    </thead>
                    {loading ? (
                        <tbody>
                            <tr style={{ height: `${perPage * 50}px` }}>
                                <td colSpan={3}>
                                    <EllipsisLoader dark />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {(!items || items?.length === 0) && (
                                <tr className="text-center">
                                    <td className="fw-600" colSpan={3}>
                                        <div className="w-25 mx-auto">
                                            <NoData />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {items?.length > 0 &&
                                items.map((item, index) => {
                                    return (
                                        <tr key={index} className="bg-white">
                                            <td>{item.organization}</td>
                                            <td>
                                                <ItemStatusSwitch
                                                    item={item}
                                                    activateHandler={
                                                        activateCampaignOwner
                                                    }
                                                    disableHandler={
                                                        disableCampaignOwner
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-end gap-1">
                                                    <button
                                                        className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-2"
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                item
                                                            )
                                                            openEditModal()
                                                        }}
                                                    >
                                                        <div className="crud-icon">
                                                            {Icons.edit()}
                                                        </div>
                                                        <div className="mt-1px">
                                                            <Trans>Edit</Trans>
                                                        </div>
                                                    </button>

                                                    <button
                                                        className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-2"
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                item
                                                            )
                                                            openUsersModal()
                                                        }}
                                                    >
                                                        <div className="crud-icon">
                                                            {Icons.group()}
                                                        </div>
                                                        <div className="mt-1px">
                                                            <Trans>Users</Trans>
                                                        </div>
                                                    </button>

                                                    <TableButton
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                item
                                                            )
                                                            openArchiveModal()
                                                        }}
                                                        disabled={
                                                            item.is_active
                                                        }
                                                        tooltip={
                                                            item.is_active
                                                                ? "Active campaign owner cannot be archived"
                                                                : ""
                                                        }
                                                        icon={Icons.archive()}
                                                    >
                                                        <Trans>Archive</Trans>
                                                    </TableButton>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            {items?.length > 0 &&
                                items.length !== perPage &&
                                Array.from(
                                    Array(perPage - items.length).keys()
                                ).map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="invisible border-1 border-light bg-light h-50px"
                                        >
                                            <td className="fw-bold" colSpan={4}>
                                                Data
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    )}
                </table>
            </div>
            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default CampaignOwnersTable
