import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { toast } from "react-toastify"
import { useApi } from "./ApiProvider"

const DashboardContext = createContext()
export const useDashboard = () => useContext(DashboardContext)

const DashboardProvider = ({ children }) => {
    // States and Hooks
    const { dashboard, campaign } = useApi()

    const [countItemsLoading, setCountItemsLoading] = useState(false)
    const [countItems, setCountItems] = useState(null)

    const activeCampaignsPerPage = 3
    const [activeCampaignsTableLoading, setActiveCampaignsTableLoading] =
        useState(false)
    const [activeCampaignsCurrentPage, setActiveCampaignsCurrentPage] =
        useState(1)
    const [activeCampaignsTotalItems, setActiveCampaignsTotalItems] =
        useState(null)
    const [activeCampaignsItems, setActiveCampaignsItems] = useState(null)

    // Methods
    const fetchCounts = async () => {
        const { data, error } = await dashboard.fetchCounts()
        if (data) setCountItems(data)
        if (error) toast.error("Something went wrong")
    }

    const initializeCountItems = async () => {
        setCountItemsLoading(true)
        await fetchCounts()
        setCountItemsLoading(false)
    }

    const fetchActiveCampaigns = async () => {
        const { data, error } = await campaign.fetchActive({
            size: activeCampaignsPerPage,
            page: activeCampaignsCurrentPage,
        })

        if (data) {
            setActiveCampaignsTotalItems(data.count)
            setActiveCampaignsItems(data.rows)
        }

        if (error) toast.error("Something went wrong")
    }

    const initializeActiveCampaignsTable = async () => {
        setActiveCampaignsTableLoading(true)
        await fetchActiveCampaigns()
        setActiveCampaignsTableLoading(false)
    }

    const initialize = async () => {
        await initializeCountItems()
        await initializeActiveCampaignsTable()
    }

    // UseEffect
    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        initializeActiveCampaignsTable()
    }, [activeCampaignsCurrentPage])

    // Binding
    const valueObject = {
        // States
        countItemsLoading,
        setCountItemsLoading,
        countItems,
        setCountItems,

        activeCampaignsTableLoading,
        setActiveCampaignsTableLoading,
        activeCampaignsPerPage,
        activeCampaignsCurrentPage,
        setActiveCampaignsCurrentPage,
        activeCampaignsTotalItems,
        setActiveCampaignsTotalItems,
        activeCampaignsItems,
        setActiveCampaignsItems,

        // Methods
        fetchCounts,
        initializeCountItems,

        fetchActiveCampaigns,
        initializeActiveCampaignsTable,

        initialize,
    }

    // Render
    return (
        <DashboardContext.Provider value={valueObject}>
            {children}
        </DashboardContext.Provider>
    )
}

export default DashboardProvider
