import React, { useEffect } from "react"
import { useFormik } from "formik"
import {
    useCampaigns,
    CampaignUsageLimitTypes,
    CampaignStatuses,
} from "../../../../../providers/CampaignsProvider"
import { CampaignUsageLimitsFormSchema } from "../../../../../utilities/FormValidations"
import FormFields from "./FormFields"
import FormButtons from "./FormButtons"
import { usageLimitTypeDecrementCheck } from "../../../../../functions/usageLimitTypeDecrementCheck"

const UsageLimitsForm = () => {
    // States and Hooks
    const {
        campaignValues,
        setCampaignValues,
        setError,
        editModalNextStepHandler: nextStepHandler,
        selectedItem,
    } = useCampaigns()

    const campaignUsageLimitsFormik = useFormik({
        initialValues: {
            usageLimitCount:
                campaignValues?.usageLimitCount === -1
                    ? undefined
                    : campaignValues?.usageLimitCount,
            unlimited: campaignValues?.unlimited ? true : false,
            usageLimitType: campaignValues?.usageLimitType
                ? campaignValues.usageLimitType
                : "",
        },
        enableReinitialize: true,
        onSubmit: async values => {
            setError("")

            if (values.unlimited) {
                setCampaignValues({
                    ...campaignValues,
                    usageLimitCount: -1,
                    usageLimitType: CampaignUsageLimitTypes.AllTime.label,
                    unlimited: true,
                })
                return nextStepHandler()
            }

            const validationResponse =
                await CampaignUsageLimitsFormSchema.validate({
                    usageLimitCount: values.usageLimitCount,
                    usageLimitType: values.usageLimitType,
                }).catch(error => setError(error.message))

            let decreasedUsageLimit = false

            if (selectedItem.status.name !== CampaignStatuses.Created)
                decreasedUsageLimit = usageLimitTypeDecrementCheck({
                    currentCount: selectedItem.usage_limit_count,
                    selectedCount: values.usageLimitCount,
                    currentType: selectedItem.usage_limit_type,
                    selectedType: values.usageLimitType,
                })

            if (decreasedUsageLimit)
                return setError("Usage limit decrement is not allowed")

            if (validationResponse) {
                setCampaignValues({
                    ...campaignValues,
                    usageLimitCount: values.usageLimitCount,
                    usageLimitType: values.usageLimitType,
                    unlimited: false,
                })
                return nextStepHandler()
            }
        },
    })

    // UseEffects
    useEffect(() => {
        if (!campaignValues?.usageLimitsFormInitialization)
            setCampaignValues({
                ...campaignValues,
                usageLimitCount: selectedItem.usage_limit_count,
                usageLimitType: selectedItem.usage_limit_type,
                unlimited: selectedItem.usage_limit_count === -1,
                usageLimitsFormInitialization: true,
            })
    }, [])

    return (
        <form onSubmit={campaignUsageLimitsFormik.handleSubmit}>
            <FormFields formik={campaignUsageLimitsFormik} />
            <FormButtons />
        </form>
    )
}

export default UsageLimitsForm
