import React from "react"
import Layout from "../components/template/Layout"
import styles from "../styles/modules/pages/Login.module.scss"
import { Icons } from "../utilities/Icons"
import Button from "../components/template/Button"
import ErrorBox from "../components/template/ErrorBox"
import EllipsisLoader from "../components/template/EllipsisLoader"
import { useLogin } from "../providers/LoginProvider"
import { Images } from "../utilities/Images"
import { Trans, useTranslation } from "react-i18next"

const Login = () => {
    const { t } = useTranslation()

    const {
        loadingLoginButton,
        error,
        loginFormik,
        onInputFocus,
        onInputBlur,
    } = useLogin()

    return (
        <Layout>
            <form
                className={`${styles.card}`}
                onSubmit={loginFormik.handleSubmit}
            >
                <div className={styles.container}>
                    <div className={styles.logo}>
                        <img
                            src={Images.Logo}
                            alt="Logo"
                            className="img-fluid"
                        />
                    </div>
                    <ErrorBox error={error} />
                    <div className={styles.input}>
                        <div className="small-icon">{Icons.user()}</div>
                        <input
                            id="username"
                            name="username"
                            type="text"
                            className="form-control"
                            placeholder={t("Username")}
                            onChange={loginFormik.handleChange}
                            value={loginFormik.values.username}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                        />
                    </div>
                    <div className={styles.input}>
                        <div className={`small-icon ${styles.icon}`}>
                            {Icons.key()}
                        </div>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder={t("Password")}
                            onChange={loginFormik.handleChange}
                            value={loginFormik.values.password}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                        />
                    </div>
                    <div className="text-center">
                        <Button type="submit" className={styles.button}>
                            {loadingLoginButton ? (
                                <EllipsisLoader />
                            ) : (
                                <Trans>Login</Trans>
                            )}
                        </Button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default Login
