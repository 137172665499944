const formatDateTimeValue = date =>
    date.toLocaleString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    })

module.exports = formatDateTimeValue
