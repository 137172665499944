import React from "react"
import Layout from "../components/template/Layout"
import CampaignOwnersTable from "../components/page-components/campaign-owner/CampaignOwnersTable"
import { useCampaignOwners } from "../providers/CampaignOwnersProvider"
import CreateCampaignOwnerModal from "../components/page-components/campaign-owner/CreateCampaignOwnerModal"
import EditCampaignOwnerModal from "../components/page-components/campaign-owner/EditCampaignOwnerModal"
import { Icons } from "../utilities/Icons"
import CampaignOwnerUsersModal from "../components/page-components/campaign-owner/campaign-owner-user/CampaignOwnerUsersModal"
import CampaignOwnerUsersProvider from "../components/page-components/campaign-owner/campaign-owner-user/CampaignOwnerUsersProvider"
import ArchiveCampaignOwnerModal from "../components/page-components/campaign-owner/ArchiveCampaignOwnerModal"
import ArchivedCampaignOwnersTable from "../components/page-components/campaign-owner/ArchivedCampaignOwnersTable"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { TableViewTypes } from "../providers/AppProvider"
import { Trans, useTranslation } from "react-i18next"

const CampaignOwners = () => {
    const { t } = useTranslation()

    const {
        isEditModalOpen,
        isCreateModalOpen,
        isUsersModalOpen,
        isArchiveModalOpen,
        setIsCreateModalOpen,
        setTableView,
        loading,
        tableView,
    } = useCampaignOwners()

    const disabledMainTable = tableView === TableViewTypes.archived && loading
    const disabledArchivedTable = tableView === TableViewTypes.main && loading

    const openCreateModal = () => setIsCreateModalOpen(true)

    return (
        <Layout>
            <div className="p-4">
                <button
                    className="btn btn-primary mb-3 d-flex align-items-center"
                    onClick={openCreateModal}
                >
                    <div className="small-icon">{Icons.add()}</div>
                    <div>
                        <Trans>Add Campaign Owner</Trans>
                    </div>
                </button>

                {isCreateModalOpen && <CreateCampaignOwnerModal />}
                {isEditModalOpen && <EditCampaignOwnerModal />}
                {isArchiveModalOpen && <ArchiveCampaignOwnerModal />}

                {isUsersModalOpen && (
                    <CampaignOwnerUsersProvider>
                        <CampaignOwnerUsersModal />
                    </CampaignOwnerUsersProvider>
                )}

                <Tabs
                    defaultActiveKey="main"
                    className="fw-bold mb-3 table-tabs"
                    onSelect={eventKey => {
                        setTableView(eventKey)
                    }}
                    fill
                >
                    <Tab
                        eventKey={TableViewTypes.main}
                        title={t("Main")}
                        disabled={disabledMainTable}
                    >
                        <CampaignOwnersTable />
                    </Tab>
                    <Tab
                        eventKey={TableViewTypes.archived}
                        title={t("Archived")}
                        disabled={disabledArchivedTable}
                    >
                        <ArchivedCampaignOwnersTable />
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    )
}

export default CampaignOwners
