import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useSegments } from "../../../providers/SegmentsProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import { EditSegmentFormSchema } from "../../../utilities/FormValidations"
import { toast } from "react-toastify"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import Input from "../../template/Input"
import { SegmentsGenderTypes } from "../../../providers/SegmentsProvider"
import useScreen from "../../../api/useScreen"
import { CirclePicker } from "react-color"
import { Trans, useTranslation } from "react-i18next"

const EditSegmentModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { segment } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isEditModalOpen: isOpen,
        setIsEditModalOpen: setIsOpen,
        initialize,
        colorItems,
        selectedItem,
    } = useSegments()

    const editSegmentFormik = useFormik({
        initialValues: {
            title: selectedItem.title,
            gender: selectedItem.gender,
            color: selectedItem.color,
        },
        onSubmit: async values => {
            setError("")
            setLoading(true)
            const validationResponse = await EditSegmentFormSchema.validate({
                title: values.title,
                gender: values.gender,
                color: values.color,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await segment.update({
                    token: selectedItem.token,
                    title: values.title,
                    gender: values.gender,
                    color: values.color,
                })
                if (error) setError(error)
                if (data) {
                    closeModal()
                    await initialize()
                    toast.success(t("Segment edited successfully"))
                }
            }
            setLoading(false)
        },
    })

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={editSegmentFormik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Edit Segment</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Title</Trans>:
                        </label>
                        <Input
                            id="title"
                            name="title"
                            type="text"
                            disabled={loading}
                            onChange={editSegmentFormik.handleChange}
                            value={editSegmentFormik.values.title}
                            className="form-control rounded-0"
                            placeholder="Title"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Gender</Trans>:
                        </label>
                        <Form.Select
                            id="gender"
                            name="gender"
                            disabled={loading}
                            onChange={editSegmentFormik.handleChange}
                            value={editSegmentFormik.values.gender}
                            className="form-control rounded-0"
                        >
                            <option value="">
                                <Trans>Select Gender</Trans>
                            </option>
                            {SegmentsGenderTypes.map(gender => {
                                return (
                                    <option value={gender}>
                                        <Trans>{gender}</Trans>
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </div>

                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Color</Trans>:
                        </label>
                        <CirclePicker
                            id="color"
                            name="color"
                            className="border rounded-0"
                            width="100%"
                            triangle="hide"
                            colors={colorItems}
                            color={editSegmentFormik.values.color}
                            onChange={color => {
                                editSegmentFormik.setFieldValue(
                                    "color",
                                    color.hex
                                )
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                            type="button"
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Update</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditSegmentModal
