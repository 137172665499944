export const AppRoutes = {
    home: "/",
    login: "/login",
    filters: "/filters",
    campaignOwners: "/campaign-owners",
    admins: "/admins",
    segments: "/segments",
    users: "/users",
    campaigns: "/campaigns",
    logoutActions: "/logout-actions",
    logout: "/logout",
}

export const UnprotectedRoutes = [AppRoutes.login]

export const DashboardRoutes = [
    AppRoutes.filters,
    AppRoutes.campaignOwners,
    AppRoutes.campaigns,
    AppRoutes.segments,
    AppRoutes.users,
    AppRoutes.admins,
]
