import React from "react"
import styles from "../../styles/modules/components/ColorSegment.module.scss"
import { Trans } from "react-i18next"

const ColorSegment = ({ color, text = "", className = "" }) => {
    const rgb = parseInt(color.substring(1), 16)
    const red = (rgb >> 16) & 0xff
    const green = (rgb >> 8) & 0xff
    const blue = (rgb >> 0) & 0xff
    const brightness = 0.2126 * red + 0.7152 * green + 0.0722 * blue

    let darkColor = false

    if (brightness < 200) darkColor = true

    return (
        <div
            className={`${styles.container} ${
                darkColor && "text-white"
            } ${className}`}
            style={{ backgroundColor: color }}
        >
            <Trans>{text}</Trans>
        </div>
    )
}

export default ColorSegment
