import React from "react"
import CampaignDetails from "./CampaignDetails"
import FormButtons from "./FormButtons"

const SummaryForm = () => {
    return (
        <form>
            <CampaignDetails />
            <FormButtons />
        </form>
    )
}

export default SummaryForm
