import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { useApi } from "./ApiProvider"
import { toast } from "react-toastify"
import { TableViewTypes } from "./AppProvider"

const SegmentsContext = createContext()
export const useSegments = () => useContext(SegmentsContext)

const SegmentsProvider = ({ children }) => {
    // States and Hooks
    const { segment, color } = useApi()
    const perPage = 7
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(null)
    const [colorItems, setColorItems] = useState(null)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [tableView, setTableView] = useState(TableViewTypes.main)

    // Methods
    const fetchSegments = async () => {
        const { data, error } = await segment.fetch({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(data.count)
        setItems(data.rows)
    }

    const fetchArchivedSegments = async () => {
        const { data, error } = await segment.fetchArchived({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(data.count)
        setItems(data.rows)
    }

    const fetchColors = async () => {
        const { data, error } = await color.fetch()
        const colorsArray = data.rows.map(item => item.color)
        setColorItems(colorsArray)
    }

    const initialize = async () => {
        setLoading(true)
        if (tableView === TableViewTypes.main) await fetchSegments()
        if (tableView === TableViewTypes.archived) await fetchArchivedSegments()
        await fetchColors()
        setLoading(false)
    }

    const activateSegment = async token => {
        const { data, error } = await segment.activate({ token })
        if (error) {
            toast.error("Network Error")
            await initialize()
        }
        if (data) {
            const newItems = items.map(item => {
                if (item.token === data.token) return data
                return item
            })
            return setItems(newItems)
        }
    }

    const disableSegment = async token => {
        const { data, error } = await segment.disable({ token })
        if (error) {
            toast.error("Network Error")
            await initialize()
        }
        if (data) {
            const newItems = items.map(item => {
                if (item.token === data.token) return data
                return item
            })
            return setItems(newItems)
        }
    }

    // UseEffect
    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        setCurrentPage(1)
        initialize()
    }, [tableView])

    useEffect(() => {
        initialize()
    }, [currentPage])

    // Binding
    const valueObject = {
        // States
        perPage,

        loading,
        setLoading,

        items,
        setItems,

        currentPage,
        setCurrentPage,

        totalItems,
        setTotalItems,

        colorItems,
        setColorItems,

        isCreateModalOpen,
        setIsCreateModalOpen,

        isEditModalOpen,
        setIsEditModalOpen,

        isArchiveModalOpen,
        setIsArchiveModalOpen,

        selectedItem,
        setSelectedItem,

        tableView,
        setTableView,

        // Methods
        fetchSegments,
        fetchArchivedSegments,
        fetchColors,
        initialize,
        activateSegment,
        disableSegment,
    }

    // Render
    return (
        <SegmentsContext.Provider value={valueObject}>
            {children}
        </SegmentsContext.Provider>
    )
}

export default SegmentsProvider

export const SegmentsGenderTypes = ["Male", "Female", "Other", "All"]
