import React from "react"
import { Trans } from "react-i18next"

const CampaignDetailsItem = ({ title = "", value = "", children }) => {
    return (
        <div className="d-flex gap-2 mb-2 pb-2 col-12 px-0 border-bottom align-items-center">
            <div className="fw-500">
                <Trans>{title}</Trans>:
            </div>
            {value && <div>{value}</div>}
            {children}
        </div>
    )
}

export default CampaignDetailsItem
