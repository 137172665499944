import React, { useState } from "react"
import { Modal, Form } from "react-bootstrap"
import * as XLSX from "xlsx"
import { useCampaigns } from "../../../../providers/CampaignsProvider"
import useScreen from "../../../../api/useScreen"
import formatDateTimeValue from "../../../../functions/formatDateTimeValue"
import CampaignDetailsItem from "./CampaignDetailsItem"
import CampaignOwnerItem from "./CampaignOwnerItem"
import FiltersItem from "./FiltersItem"
import SegmentsItem from "./SegmentsItem"
import LocationsItem from "./LocationsItem"
import { useApi } from "../../../../providers/ApiProvider"
import { toast } from "react-toastify"
import EllipsisLoader from "../../../template/EllipsisLoader"
import { Trans, useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"

const ViewModal = () => {
    // States and Hooks
    const { t } = useTranslation()

    const { campaign } = useApi()

    const {
        isViewModalOpen: isOpen,
        setIsViewModalOpen: setIsOpen,
        selectedItem,
        setSelectedItem,
    } = useCampaigns()

    const { isMobile } = useScreen()

    const [downloadCodesLoading, setDownloadCodesLoading] = useState(false)

    // Methods
    const closeModal = () => {
        setSelectedItem(false)
        setIsOpen(false)
    }

    const downloadCodesHandler = async () => {
        setDownloadCodesLoading(true)

        const { data: codes, error } = await campaign.fetchCodes({
            token: selectedItem.token,
        })
        if (error) return toast.error(t("Something went wrong"))

        const worksheet = XLSX.utils.json_to_sheet(codes)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Codes")
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        })
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        const url = URL.createObjectURL(data)
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "codes.xlsx")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setDownloadCodesLoading(false)
    }

    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Trans>Campaign Details</Trans>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row m-0 mb-3">
                        {selectedItem?.thumbnails && (
                            <CampaignDetailsItem title="Thumbnails">
                                <Swiper
                                    className="w-100"
                                    slidesPerView={"auto"}
                                    spaceBetween={10}
                                >
                                    {JSON.parse(selectedItem.thumbnails).map(
                                        (thumbnail, index) => (
                                            <SwiperSlide className="thumbnail-view-container rounded">
                                                <img
                                                    src={thumbnail}
                                                    className="w-100 h-auto rounded thumbnail-image"
                                                    alt={`campaign-thumbnail-${index}`}
                                                />
                                            </SwiperSlide>
                                        )
                                    )}
                                </Swiper>
                            </CampaignDetailsItem>
                        )}
                        <CampaignDetailsItem
                            title="Name"
                            value={selectedItem?.name}
                        />
                        <CampaignDetailsItem
                            title="Capacity"
                            value={
                                selectedItem?.capacity
                                    ? selectedItem?.capacity
                                    : t("Visual")
                            }
                        />
                        {selectedItem?.capacity > 0 && (
                            <CampaignDetailsItem title="Codes">
                                <button
                                    className="btn btn-primary"
                                    onClick={downloadCodesHandler}
                                    disabled={downloadCodesLoading}
                                >
                                    {downloadCodesLoading && <EllipsisLoader />}
                                    {!downloadCodesLoading && (
                                        <div>
                                            <Trans>Download</Trans>
                                        </div>
                                    )}
                                </button>
                            </CampaignDetailsItem>
                        )}
                        <CampaignDetailsItem
                            title="Start"
                            value={formatDateTimeValue(
                                new Date(selectedItem?.start_date)
                            )}
                        />
                        <CampaignDetailsItem
                            title="End"
                            value={formatDateTimeValue(
                                new Date(selectedItem?.end_date)
                            )}
                        />
                        <CampaignDetailsItem
                            title="Usage limit count"
                            value={
                                selectedItem?.usage_limit_count === -1
                                    ? t("Unlimited")
                                    : selectedItem?.usage_limit_count
                            }
                        />
                        <CampaignDetailsItem
                            title="Usage limit type"
                            value={t(selectedItem?.usage_limit_type)}
                        />
                        <CampaignOwnerItem />
                        <CampaignDetailsItem title="Has feedback">
                            <Form.Check
                                className="custom-checkbox"
                                type="checkbox"
                                checked={selectedItem?.has_feedback}
                                disabled
                            />
                        </CampaignDetailsItem>
                        <CampaignDetailsItem title="Description">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: selectedItem?.description,
                                }}
                            />
                        </CampaignDetailsItem>
                        <FiltersItem />
                        <SegmentsItem />
                        {selectedItem?.locations && <LocationsItem />}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewModal
