import React from "react"
import { Link } from "react-router-dom"
import { SideNavLinks } from "../../../../providers/SideNavProvider"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { DashboardRoutes } from "../../../../utilities/AppRoutes"
import EllipsisLoader from "../../../template/EllipsisLoader"
import { Icons } from "../../../../utilities/Icons"
import { Trans, useTranslation } from "react-i18next"

const RouterLinks = () => {
    const { countItems, countItemsLoading: loading } = useDashboard()
    const { t } = useTranslation()

    return (
        <>
            <div className="p-2 pb-0">
                <div className="d-flex bg-darkgray rounded-2 text-white p-2 px-3 ps-0 align-items-center">
                    <div className="medium-icon mx-3">{Icons.link()}</div>
                    <div className="fw-bold m-0">
                        <Trans>Links</Trans>
                    </div>
                    {loading && (
                        <div className="ms-auto">
                            <EllipsisLoader />
                        </div>
                    )}
                </div>
            </div>

            {countItems && (
                <div className="row m-0 align-items-center">
                    {SideNavLinks.map(link => {
                        if (DashboardRoutes.includes(link.value)) {
                            const countItem = countItems.find(
                                item => item.label === link.label
                            )
                            return (
                                <div className="col-12 col-md-6 col-lg-4 p-2">
                                    <Link
                                        to={link.value}
                                        className="d-flex bg-primary rounded-2 text-white p-2 px-3 ps-0 align-items-center justify-content-end"
                                    >
                                        <div className="medium-icon mx-3">
                                            {link.icon}
                                        </div>
                                        <div className="d-flex flex-column flex-fill">
                                            <div className="fw-bold m-0">
                                                <Trans>{link.label}</Trans>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <div>{`${t("Total")}: ${
                                                    countItem.total
                                                }`}</div>
                                                <div>{`${t("Active")}: ${
                                                    countItem.active
                                                }`}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        }
                    })}
                </div>
            )}
        </>
    )
}

export default RouterLinks
