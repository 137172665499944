import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { useCampaigns } from "../../../providers/CampaignsProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import useScreen from "../../../api/useScreen"
import { Trans, useTranslation } from "react-i18next"

const StartModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { campaign } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isStartModalOpen: isOpen,
        setIsStartModalOpen: setIsOpen,
        initialize,
        selectedItem,
        setSelectedItem,
    } = useCampaigns()

    const campaignStartHandler = async e => {
        e.preventDefault()
        setError("")
        setLoading(true)

        const { data, error } = await campaign.start({
            token: selectedItem.token,
        })

        if (error) setError(error)

        if (data) {
            closeModal()
            await initialize()
            toast.success(t("Campaign started successfully"))
        }

        setLoading(false)
    }

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
        setSelectedItem(null)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={campaignStartHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Start Campaign</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="d-flex align-items-center gap-2 flex-wrap mb-4">
                        <Trans>Are you sure you want to start</Trans>
                        <span className="fw-bold">{selectedItem.name}</span>?
                    </div>
                    <div>
                        <span className="fw-bold">
                            <Trans>Note</Trans>:
                        </span>{" "}
                        <Trans>
                            by starting campaign the start time will be changed
                            to now and the campaign would be visible to the
                            users.
                        </Trans>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Start</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default StartModal
