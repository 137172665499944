import React from "react"
import { toast } from "react-toastify"
import { Icons } from "../../utilities/Icons"
import { useTranslation } from "react-i18next"

const CopyToClipboard = ({ text }) => {
    const { t } = useTranslation()

    const copyToClipboardHandler = () => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success(t("Copied to clipboard"))
        })
    }

    return (
        <button
            className="btn rounded-0 p-0"
            type="button"
            onClick={copyToClipboardHandler}
        >
            <div className="crud-icon">{Icons.copy()}</div>
        </button>
    )
}

export default CopyToClipboard
