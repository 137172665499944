import * as yup from "yup"
import { UsersGenderTypes } from "../providers/UsersProvider"
import { SegmentsGenderTypes } from "../providers/SegmentsProvider"
import { CampaignUsageLimitTypes } from "../providers/CampaignsProvider"

export const LoginFormSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    username: yup.string().required("Username is required"),
})

export const FiltersCreateSchema = yup.object().shape({
    order: yup
        .number()
        .typeError("Filter order is required")
        .min(1)
        .required("Filter order is required"),
    name: yup.string().required("Filter name is required"),
})

export const FiltersEditSchema = yup.object().shape({
    order: yup
        .number()
        .typeError("Filter order is required")
        .min(1)
        .required("Filter order is required"),
    name: yup.string().required("Filter name is required"),
})

export const CreateCampaignOwnerFormSchema = yup.object().shape({
    logo: yup.string().required("Logo is required"),
    organization: yup.string().required("Organization is required"),
})

export const EditCampaignOwnerFormSchema = yup.object().shape({
    logo: yup.string().required("Logo is required"),
    organization: yup.string().required("Organization is required"),
})

export const CreateCampaignOwnerUserFormSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
})

export const AdminsCreateFormSchema = yup.object().shape({
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords do not match")
        .required("Confirm password is required"),
    password: yup.string().required("Password is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
})

export const EditCampaignOwnerUserFormSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
})

export const AdminsEditFormSchema = yup.object().shape({
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords do not match")
        .required("Confirm password is required"),
    password: yup.string().required("Password is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
})

export const CreateUserFormSchema = yup.object().shape({
    birthDate: yup.date().nullable(),
    gender: yup.string().oneOf(UsersGenderTypes).required("Gender is required"),
    phoneNumber: yup
        .string()
        .required("Phone number is required")
        .matches(/^\d{10}$/, "Phone number is not valid"),
})

export const EditUserFormSchema = yup.object().shape({
    birthDate: yup.date().nullable(),
    gender: yup.string().oneOf(UsersGenderTypes).required("Gender is required"),
    phoneNumber: yup
        .string()
        .required("Phone number is required")
        .matches(/^\d{10}$/, "Phone number is not valid"),
})

export const CreateSegmentFormSchema = yup.object().shape({
    color: yup.string().required("Color is required"),
    gender: yup
        .string()
        .oneOf(SegmentsGenderTypes)
        .required("Gender is required"),
    title: yup.string().required("Title is required"),
})

export const EditSegmentFormSchema = yup.object().shape({
    color: yup.string().required("Color is required"),
    gender: yup
        .string()
        .oneOf(SegmentsGenderTypes)
        .required("Gender is required"),
    title: yup.string().required("Title is required"),
})

export const CampaignDetailsFormSchema = yup.object().shape({
    thumbnails: yup.array().of(yup.string().required()).nullable(),
    description: yup.string().required("Description is required"),
    campaignOwnerToken: yup.string().required("Campaign owner is required"),
    endDate: yup
        .date()
        .typeError("End date is required")
        .required("End date is required"),
    startDate: yup
        .date()
        .typeError("Start date is required")
        .required("Start date is required"),
    name: yup.string().required("Name is required"),
})

export const CampaignAssignmentFormSchema = yup.object().shape({
    filterTokens: yup
        .array()
        .of(yup.string().required("Filter is required"))
        .required("Filter is required"),
})

export const CampaignCreateLocationFormSchema = yup.object().shape({
    locationName: yup.string().required("Location name is required"),
    locationCoordinates: yup
        .object()
        .typeError("Please select your location on map")
        .shape({
            lat: yup.number().required("Please select your location on map"),
            lng: yup.number().required("Please select your location on map"),
        })
        .required("Please select your location on map"),
})

export const CampaignSubmitLocationsFormSchema = yup.object().shape({
    locations: yup
        .array()
        .of(
            yup
                .object()
                .shape({
                    lat: yup.number().required(),
                    lng: yup.number().required(),
                    name: yup.string().required(),
                })
                .required("Please submit at least one location")
        )
        .nullable(),
})

export const CampaignCodesFormSchema = yup.object().shape({
    countOfCodes: yup
        .number()
        .typeError("Count of codes is required")
        .min(1, "Codes count could not be less than 1")
        .required("Count of codes is required"),
})

export const CampaignUsageLimitsFormSchema = yup.object().shape({
    usageLimitCount: yup
        .number()
        .typeError("Usage limit count is required")
        .min(1, "Usage limit count could not be less than 1")
        .required("Usage limit count is required"),
    usageLimitType: yup
        .string()
        .oneOf(Object.values(CampaignUsageLimitTypes).map(item => item.label))
        .required("Usage limit type is required"),
})
