import { Navigate } from "react-router"
import { getUserToken } from "../../functions/getUserToken"
import { AppRoutes } from "../../utilities/AppRoutes"

export const ProtectedRoute = ({ children }) => {
    // Authentication
    const userToken = getUserToken()
    let isAuthenticated = userToken !== null

    // Checking the authentication
    if (!isAuthenticated) return <Navigate to={AppRoutes.login} replace />
    return children
}
