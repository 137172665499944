import { CampaignUsageLimitTypes } from "../providers/CampaignsProvider"

export const usageLimitTypeDecrementCheck = ({
    currentCount,
    selectedCount,
    currentType,
    selectedType,
}) => {
    const unlimitedToLimitedDecrement =
        currentCount === -1 && selectedCount !== -1

    if (unlimitedToLimitedDecrement) return true

    const decreasedUsageLimitCount = selectedCount < currentCount

    const currentUsageLimitTypeValue = Object.values(
        CampaignUsageLimitTypes
    ).filter(option => option.label === currentType)[0].value

    const selectedUsageLimitTypeValue = Object.values(
        CampaignUsageLimitTypes
    ).filter(option => option.label === selectedType)[0].value

    const decreasedUsageLimitType =
        selectedUsageLimitTypeValue < currentUsageLimitTypeValue

    const decreasedUsageLimit =
        decreasedUsageLimitCount || decreasedUsageLimitType

    return decreasedUsageLimit
}
