import React, { useState, useEffect, createContext, useContext } from "react"
import { toast } from "react-toastify"
import { useApi } from "../../../../../providers/ApiProvider"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { isDarkColor } from "../../../../../functions/isDarkColor"
import { CampaignAssignmentFormSchema } from "../../../../../utilities/FormValidations"
import { useTranslation } from "react-i18next"

const AssignmentFormContext = createContext()
export const useAssignmentForm = () => useContext(AssignmentFormContext)

const AssignmentFormProvider = ({ children }) => {
    // States and Hooks
    const { t } = useTranslation()

    const { segment, filter } = useApi()
    const [segmentsLoading, setSegmentsLoading] = useState(false)
    const [filtersLoading, setFiltersLoading] = useState(false)
    const [segmentItems, setSegmentItems] = useState(null)
    const [filterItems, setFilterItems] = useState(null)
    const [segmentOptions, setSegmentOptions] = useState(null)
    const [filterOptions, setFilterOptions] = useState(null)
    const [selectedSegments, setSelectedSegments] = useState(null)
    const [selectedFilters, setSelectedFilters] = useState(null)
    const {
        setError,
        campaignValues,
        setCampaignValues,
        editModalStep,
        editModalNextStepHandler: nextStepHandler,
        selectedItem,
    } = useCampaigns()

    const colorStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
                ...(isDarkColor(data.color) && { color: "white" }),
            }
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
            }
        },
        multiValueLabel: (styles, { data }) => {
            return {
                ...styles,
                ...(isDarkColor(data.color) && { color: "white" }),
            }
        },
    }

    // Methods
    const noSegmentsMessageHandler = () => t("No segments")
    const noFiltersMessageHandler = () => t("No filters")

    const submitHandler = async e => {
        e.preventDefault()
        setError("")

        let selectedSegmentTokens = []
        selectedSegmentTokens = selectedSegments?.map(({ value }) => value)

        let selectedFilterTokens = []
        selectedFilterTokens = selectedFilters?.map(({ value }) => value)

        const validationResponse = await CampaignAssignmentFormSchema.validate({
            filterTokens: selectedFilterTokens,
        }).catch(error => setError(error.message))

        if (validationResponse) {
            setCampaignValues({
                ...campaignValues,
                segments: selectedSegments.map(
                    selectedSegment =>
                        segmentItems.filter(
                            item => item.token === selectedSegment.value
                        )[0]
                ),
                filters: selectedFilters.map(
                    selectedFilter =>
                        filterItems.filter(
                            item => item.token === selectedFilter.value
                        )[0]
                ),
            })
            nextStepHandler()
        }
    }

    const fetchSegmentItems = async () => {
        setSegmentsLoading(true)
        const { data, error } = await segment.fetchActive()
        if (error) toast.error(t("Something went wrong"))
        if (data) {
            setSegmentItems(data.rows)
            setSegmentOptions(
                data.rows.map(({ token, title, color }) => ({
                    value: token,
                    label: title,
                    color,
                }))
            )
        }
        setSegmentsLoading(false)
    }

    const fetchFilterItems = async () => {
        setFiltersLoading(true)
        const { data, error } = await filter.fetchActive()
        if (error) toast.error(t("Something went wrong"))
        if (data) {
            setFilterItems(data.rows)
            setFilterOptions(
                data.rows.map(({ token, name }) => ({
                    value: token,
                    label: name,
                }))
            )
        }
        setFiltersLoading(false)
    }

    // UseEffects
    useEffect(() => {
        fetchSegmentItems()
        fetchFilterItems()
        if (!campaignValues?.assignmentFormInitialization)
            setCampaignValues({
                ...campaignValues,
                segments: selectedItem.segments,
                filters: selectedItem.filters,
                assignmentFormInitialization: true,
            })
    }, [])

    useEffect(() => {
        fetchSegmentItems()
        fetchFilterItems()
    }, [editModalStep])

    useEffect(() => {
        if (campaignValues?.segments) {
            const initialSelectedSegments = campaignValues.segments.map(
                ({ token, title, color }) => ({
                    value: token,
                    label: title,
                    color,
                })
            )
            setSelectedSegments(initialSelectedSegments)
        }

        if (campaignValues?.filters) {
            const initialSelectedFilters = campaignValues.filters.map(
                ({ token, name }) => ({
                    value: token,
                    label: name,
                })
            )
            setSelectedFilters(initialSelectedFilters)
        }
    }, [segmentItems, filterItems])

    // Binding
    const valueObject = {
        // States
        segmentsLoading,
        setSegmentsLoading,

        filtersLoading,
        setFiltersLoading,

        segmentItems,
        setSegmentItems,

        filterItems,
        setFilterItems,

        segmentOptions,
        setSegmentOptions,

        filterOptions,
        setFilterOptions,

        selectedSegments,
        setSelectedSegments,

        selectedFilters,
        setSelectedFilters,

        colorStyles,

        // Methods
        noSegmentsMessageHandler,
        noFiltersMessageHandler,
        submitHandler,
    }

    // Render
    return (
        <AssignmentFormContext.Provider value={valueObject}>
            {children}
        </AssignmentFormContext.Provider>
    )
}

export default AssignmentFormProvider
