import React from "react"
import { useTranslation } from "react-i18next"
import { useApp } from "../../providers/AppProvider"
import styles from "../../styles/modules/components/LangSwitch.module.scss"

const LangSwitch = () => {
    // States and Hooks
    const { i18n } = useTranslation()
    const { changeLanguage } = useApp()

    // Render
    return (
        <div className={`${styles["lang-switch"]}`}>
            <div>
                <button
                    onClick={() => changeLanguage("en")}
                    className={`py-2 px-3 ${
                        i18n.language === AppLangs.English &&
                        styles["active-lang"]
                    }`}
                >
                    EN
                </button>
            </div>
            <div>
                <button
                    onClick={() => changeLanguage("tr")}
                    className={`py-2 px-3 ${
                        i18n.language === AppLangs.Turkish &&
                        styles["active-lang"]
                    }`}
                >
                    TR
                </button>
            </div>
        </div>
    )
}

export const AppLangs = {
    Turkish: "tr",
    English: "en",
}

export default LangSwitch
