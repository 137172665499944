import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { useApi } from "./ApiProvider"
import { toast } from "react-toastify"
import { TableViewTypes } from "./AppProvider"

const UsersContext = createContext()
export const useUsers = () => useContext(UsersContext)

const UsersProvider = ({ children }) => {
    // States and Hooks
    const { user } = useApi()
    const perPage = 7
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
    const [isSegmentsModalOpen, setIsSegmentsModalOpen] = useState(false)
    const [tableView, setTableView] = useState(TableViewTypes.main)

    // Methods
    const fetchUsers = async () => {
        const response = await user.fetch({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        const userItems = response.data.rows
        setItems(userItems)
    }

    const initialize = async () => {
        setLoading(true)
        if (tableView === TableViewTypes.main) await fetchUsers()
        if (tableView === TableViewTypes.archived) await fetchArchivedUsers()
        setLoading(false)
    }

    const activateUser = async token => {
        const { data, error } = await user.activate({ token })
        if (error) {
            toast.error("Network Error")
            await initialize()
        }
        if (data) {
            const newItems = items.map(item => {
                if (item.token === data.token) return data
                return item
            })
            return setItems(newItems)
        }
    }

    const disableUser = async token => {
        const { data, error } = await user.disable({ token })
        if (error) {
            toast.error("Network Error")
            await initialize()
        }
        if (data) {
            const newItems = items.map(item => {
                if (item.token === data.token) return data
                return item
            })
            return setItems(newItems)
        }
    }

    const fetchArchivedUsers = async () => {
        setLoading(true)
        const response = await user.fetchArchived({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        const userItems = response.data.rows
        setItems(userItems)
        setLoading(false)
    }

    // UseEffect
    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        setCurrentPage(1)
        if (tableView === TableViewTypes.main) initialize()
        if (tableView === TableViewTypes.archived) fetchArchivedUsers()
    }, [tableView])

    useEffect(() => {
        initialize()
    }, [currentPage])

    // Binding
    const valueObject = {
        // States
        perPage,

        loading,
        setLoading,

        items,
        setItems,

        currentPage,
        setCurrentPage,

        totalItems,
        setTotalItems,

        selectedItem,
        setSelectedItem,

        isCreateModalOpen,
        setIsCreateModalOpen,

        isEditModalOpen,
        setIsEditModalOpen,

        isArchiveModalOpen,
        setIsArchiveModalOpen,

        isSegmentsModalOpen,
        setIsSegmentsModalOpen,

        tableView,
        setTableView,

        // Methods
        initialize,
        activateUser,
        disableUser,
    }

    // Render
    return (
        <UsersContext.Provider value={valueObject}>
            {children}
        </UsersContext.Provider>
    )
}

export default UsersProvider

export const UsersGenderTypes = ["Male", "Female", "Other"]
