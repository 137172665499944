import { axiosRequest } from "../axiosRequest"

export const CampaignOwnerUserController = {
    fetch: async ({ size, page }) =>
        await axiosRequest({
            url: "/campaign-owner-user/fetch",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    create: async ({ campaign_owner_token, email, password }) =>
        await axiosRequest({
            url: "/campaign-owner-user/create",
            method: "post",
            body: { campaign_owner_token, email, password },
            isProtected: true,
        }),
    update: async ({ token, email, password }) =>
        await axiosRequest({
            url: "/campaign-owner-user/update",
            method: "post",
            body: { token, email, password },
            isProtected: true,
        }),
    delete: async ({ token }) =>
        await axiosRequest({
            url: "/campaign-owner-user/delete",
            method: "post",
            body: { token },
            isProtected: true,
        }),
}
