import { axiosRequest } from "../axiosRequest"

export const FilterController = {
    fetch: async ({ size, page }) =>
        await axiosRequest({
            url: "/filter/fetch",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    fetchArchived: async ({ size, page }) =>
        await axiosRequest({
            url: "/filter/fetch-archived",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    fetchActive: async () =>
        await axiosRequest({
            url: "/filter/fetch-active",
            method: "post",
            isProtected: true,
        }),
    create: async ({ name, order, isActive }) =>
        await axiosRequest({
            url: "/filter/create",
            method: "post",
            body: { name, order, is_active: isActive },
            isProtected: true,
        }),
    update: async ({ token, name, order }) =>
        await axiosRequest({
            url: "/filter/update",
            method: "post",
            body: { token, name, order },
            isProtected: true,
        }),
    activate: async ({ token }) =>
        await axiosRequest({
            url: "/filter/activate",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    disable: async ({ token }) =>
        await axiosRequest({
            url: "/filter/disable",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    archive: async ({ token }) =>
        await axiosRequest({
            url: "/filter/archive",
            method: "post",
            body: { token },
            isProtected: true,
        }),
}
