import React, { useState, useEffect } from "react"
import Select from "react-select"
import FormButtons from "./FormButtons"
import { useAssignmentForm } from "./AssignmentFormProvider"
import { useTranslation } from "react-i18next"

const AssignmentForm = () => {
    // States and Hooks
    const { t } = useTranslation()
    const {
        segmentsLoading,
        filtersLoading,
        segmentOptions,
        filterOptions,
        selectedSegments,
        setSelectedSegments,
        selectedFilters,
        setSelectedFilters,
        colorStyles,
        noSegmentsMessageHandler,
        noFiltersMessageHandler,
        submitHandler,
    } = useAssignmentForm()

    // Render
    return (
        <form>
            <div className="row m-0 mb-3">
                <Select
                    isDisabled={segmentsLoading}
                    isLoading={segmentsLoading}
                    value={selectedSegments}
                    onChange={setSelectedSegments}
                    options={segmentOptions}
                    styles={colorStyles}
                    className="basic-multi-select"
                    classNamePrefix="campaign-segments-select"
                    placeholder={t("Select segments")}
                    isClearable={false}
                    isMulti
                    noOptionsMessage={noSegmentsMessageHandler}
                />
            </div>
            <div className="row m-0 mb-3">
                <Select
                    isDisabled={filtersLoading}
                    isLoading={filtersLoading}
                    value={selectedFilters}
                    onChange={setSelectedFilters}
                    options={filterOptions}
                    className="basic-multi-select"
                    classNamePrefix="campaign-filters-select"
                    placeholder={t("Select filters")}
                    isClearable={false}
                    isMulti
                    noOptionsMessage={noFiltersMessageHandler}
                />
            </div>
            <FormButtons
                submitHandler={submitHandler}
                disabled={segmentsLoading || filtersLoading}
            />
        </form>
    )
}

export default AssignmentForm
