import React from "react"
import { Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import ReactQuill from "react-quill"
import Input from "../../../../template/Input"
import useScreen from "../../../../../api/useScreen"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { getBase64String } from "../../../../../functions/getBase64String"
import { Trans, useTranslation } from "react-i18next"
import { tr, enGB } from "date-fns/locale"
import { Icons } from "../../../../../utilities/Icons"
import { Swiper, SwiperSlide } from "swiper/react"

const FormFields = ({ formik }) => {
    const { t, i18n } = useTranslation()

    const { isMobile } = useScreen()
    const { campaignOwnerItems, setError } = useCampaigns()

    const minDate = new Date()
    const minTime = new Date().toTimeString

    const disableAddThumbnailButton = formik.values.thumbnails.length > 5

    const openFileSelect = e => {
        e.preventDefault()
        document.getElementById("image").click()
    }

    const selectImageHandler = async e => {
        if (e.target.files[0].size > 300 * 1024)
            return setError(
                "Image is too large. Maximum allowable size is 300 KB"
            )
        const base64Image = await getBase64String(e.target.files[0])
        const currentThumbnails = formik.values.thumbnails
        const newThumbnails = [...currentThumbnails, base64Image]
        formik.setFieldValue("thumbnails", newThumbnails)
    }

    const removeImageHandler = index => {
        const currentThumbnails = formik.values.thumbnails
        currentThumbnails.splice(index, 1)
        formik.setFieldValue("thumbnails", currentThumbnails)
    }

    let locale = enGB
    if (i18n.language === "tr") locale = tr

    return (
        <div className="row m-0">
            <div className="mb-3 col-12 col-sm-6 p-0 pe-sm-1">
                <label className="fw-500 d-flex align-items-center gap-1">
                    <Trans>Name</Trans>:
                    <span className="fs-xs">{`(${formik.values.name.length}/70)`}</span>
                </label>
                <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="form-control rounded-0"
                    placeholder="Enter name"
                    maxLength={70}
                />
            </div>

            <div className="mb-3 col-12 col-sm-6 p-0 ps-sm-1">
                <label className="fw-500">
                    <Trans>Campaign owner</Trans>:
                </label>
                <Form.Select
                    id="campaignOwnerToken"
                    name="campaignOwnerToken"
                    onChange={formik.handleChange}
                    value={formik.values.campaignOwnerToken}
                    className="form-control rounded-0"
                >
                    <option value="">
                        <Trans>Select campaign owner</Trans>
                    </option>
                    {campaignOwnerItems?.map(item => {
                        return (
                            <option value={item.token} key={item.token}>
                                {item.organization}
                            </option>
                        )
                    })}
                </Form.Select>
            </div>

            <div className="mb-3 col-12 col-sm-6 p-0 pe-sm-1">
                <label className="fw-500 w-100">
                    <Trans>Start date</Trans>:
                </label>
                <DatePicker
                    id="startDate"
                    name="startDate"
                    onChange={date => {
                        formik.setFieldValue("startDate", date)
                    }}
                    selected={formik.values.startDate}
                    withPortal={isMobile}
                    className="form-control rounded-0"
                    wrapperClassName="w-100"
                    dropdownMode="select"
                    placeholderText={t("Select start date and time")}
                    dateFormat="dd/MM/yyyy , HH:mm"
                    shouldCloseOnSelect
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    minDate={minDate}
                    maxDate={formik.values.endDate}
                    locale={locale}
                />
            </div>

            <div className="mb-3 col-12 col-sm-6 p-0 ps-sm-1">
                <label className="fw-500 w-100">
                    <Trans>End date</Trans>:
                </label>
                <DatePicker
                    id="endDate"
                    name="endDate"
                    onChange={date => {
                        formik.setFieldValue("endDate", date)
                    }}
                    selected={formik.values.endDate}
                    withPortal={isMobile}
                    className="form-control rounded-0"
                    wrapperClassName="w-100"
                    dropdownMode="select"
                    placeholderText={t("Select end date and time")}
                    dateFormat="dd/MM/yyyy , HH:mm"
                    shouldCloseOnSelect
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    minDate={formik.values.startDate}
                    locale={locale}
                />
            </div>

            <div className="mb-3 col-12 col-sm-6 p-0 d-flex align-items-center gap-2">
                <label className="fw-500 no-select" htmlFor="status-checkbox">
                    <Trans>Has feedback</Trans>:
                </label>
                <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    id="hasFeedback"
                    name="hasFeedback"
                    onChange={formik.handleChange}
                    checked={formik.values.hasFeedback}
                />
            </div>

            <div className="mb-3 col-12 p-0">
                <label className="fw-500">
                    <Trans>Description</Trans>:
                </label>
                <ReactQuill
                    theme="snow"
                    id="description"
                    name="description"
                    className="description-editor"
                    value={formik.values.description}
                    onChange={htmlEntity =>
                        formik.setFieldValue("description", htmlEntity)
                    }
                />
            </div>

            <div className="mb-3 col-12 p-0">
                <div className="w-100">
                    <label className="fw-500">
                        <Trans>Thumbnails</Trans>:
                    </label>
                    <Swiper
                        className="w-100"
                        slidesPerView={"auto"}
                        spaceBetween={10}
                    >
                        {formik.values.thumbnails.length > 0 &&
                            formik.values.thumbnails.map((thumbnail, index) => (
                                <SwiperSlide className="thumbnail-view-container rounded position-relative">
                                    <img
                                        src={thumbnail}
                                        className="w-100 h-auto rounded thumbnail-image"
                                        alt={`campaign-thumbnail-${index}`}
                                    />
                                    <div className="position-absolute top-50 translate-middle start-50">
                                        <button
                                            className="btn p-2 d-none thumbnail-remove"
                                            onClick={e => {
                                                e.preventDefault()
                                                removeImageHandler(index)
                                            }}
                                        >
                                            <div className="small-icon">
                                                {Icons.trash()}
                                            </div>
                                        </button>
                                    </div>
                                </SwiperSlide>
                            ))}
                        <SwiperSlide
                            className={`thumbnail-view-container rounded ${
                                disableAddThumbnailButton && "d-none"
                            }`}
                        >
                            <button
                                className="btn thumbnail-add-container rounded"
                                type="button"
                                onClick={openFileSelect}
                                disabled={disableAddThumbnailButton}
                            >
                                +
                            </button>
                            <input
                                id="image"
                                name="image"
                                type="file"
                                onChange={selectImageHandler}
                                hidden
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default FormFields
