import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import ColorSegment from "../../../../template/ColorSegment"
import { Trans } from "react-i18next"

const SegmentsItem = () => {
    const { campaignValues } = useCampaigns()

    return (
        <div
            className={`d-flex gap-2 mb-2 pb-2 col-12 px-0 ${
                campaignValues?.locations?.length > 0 ? "border-bottom" : ""
            } align-items-center`}
        >
            <div className="fw-500">
                <Trans>Segments</Trans>:
            </div>
            <div className="d-flex gap-2 align-items-center">
                {campaignValues?.segments.map(segment => (
                    <>
                        <ColorSegment
                            color={segment.color}
                            text={segment.title}
                        />
                    </>
                ))}
            </div>
        </div>
    )
}

export default SegmentsItem
