import React from "react"
import NoData from "./NoData"

const TableNoData = ({ items = [], colspan = 0 }) => {
    return (
        <>
            {(!items || items?.length === 0) && (
                <tr className="text-center">
                    <td className="fw-600" colSpan={colspan}>
                        <div className="w-25 mx-auto">
                            <NoData />
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

export default TableNoData
