import React from "react"
import { GoogleMap, MarkerF } from "@react-google-maps/api"
import LocationsTable from "./LocationsTable"
import FormButtons from "./FormButtons"
import { useLocationsForm } from "./LocationsFormProvider"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import CreateLocationForm from "./CreateLocationForm"

const LocationsForm = () => {
    // States and Hooks
    const {
        isMarkerShown,
        locationCoordinates,
        handleMapClick,
    } = useLocationsForm()

    const { campaignValues, mapCenterLocation: centerLocation } = useCampaigns()

    // Render
    return (
        <div className="row m-0 mb-3">
            <CreateLocationForm />
            <div className="row m-0 mb-3 px-0">
                <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "400px",
                    }}
                    zoom={6}
                    center={centerLocation}
                    options={{
                        zoomControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                    }}
                    onClick={handleMapClick}
                >
                    {isMarkerShown && (
                        <MarkerF position={locationCoordinates} />
                    )}
                </GoogleMap>
            </div>

            {campaignValues?.locations &&
                campaignValues.locations.length > 0 && (
                    <div className="row m-0 mb-3 px-0">
                        <LocationsTable />
                    </div>
                )}

            <FormButtons />
        </div>
    )
}

export default LocationsForm
