import React, { useState, createContext, useContext } from "react"
import { useFormik } from "formik"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { CampaignCreateLocationFormSchema } from "../../../../../utilities/FormValidations"

const LocationsFormContext = createContext()
export const useLocationsForm = () => useContext(LocationsFormContext)

const LocationsFormProvider = ({ children }) => {
    // States & Hooks
    const [isMarkerShown, setIsMarkerShown] = useState(false)
    const [locationCoordinates, setLocationCoordinates] = useState(null)
    const {
        setError,
        campaignValues,
        setCampaignValues,
        mapCenterLocation: centerLocation,
    } = useCampaigns()

    const clearMapMarker = () => {
        setLocationCoordinates(null)
        setIsMarkerShown(false)
    }

    const campaignLocationFormik = useFormik({
        initialValues: {
            name: "",
        },
        onSubmit: async (values, { resetForm }) => {
            setError("")
            const validationResponse =
                await CampaignCreateLocationFormSchema.validate({
                    locationName: values.name,
                    locationCoordinates,
                }).catch(error => setError(error.message))

            if (validationResponse) {
                if (campaignValues?.locations?.length > 0) {
                    const duplicateLocationCoordinates =
                        campaignValues.locations.find(
                            ({ lat, lng }) =>
                                locationCoordinates.lat === lat &&
                                locationCoordinates.lng === lng
                        )

                    const duplicateLocationName = campaignValues.locations.find(
                        ({ name }) => values.name === name
                    )

                    if (duplicateLocationCoordinates)
                        setError("Selected location is already added")

                    if (duplicateLocationName)
                        setError("Entered location name is duplicate")

                    if (
                        !duplicateLocationCoordinates &&
                        !duplicateLocationName
                    ) {
                        setCampaignValues({
                            ...campaignValues,
                            locations: [
                                ...campaignValues.locations,
                                {
                                    lat: locationCoordinates.lat,
                                    lng: locationCoordinates.lng,
                                    name: values.name,
                                },
                            ],
                        })
                        clearMapMarker()
                        resetForm()
                    }
                } else {
                    setCampaignValues({
                        ...campaignValues,
                        locations: [
                            {
                                lat: locationCoordinates.lat,
                                lng: locationCoordinates.lng,
                                name: values.name,
                            },
                        ],
                    })
                    clearMapMarker()
                    resetForm()
                }
            }
        },
    })

    // Methods
    const handleMapClick = event => {
        setError("")
        const selectedPoint = {
            lat: event.latLng?.lat() ?? centerLocation.lat,
            lng: event.latLng?.lng() ?? centerLocation.lng,
        }

        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ location: selectedPoint }, (results, status) => {
            if (status === "OK") {
                const country = results[0].address_components.find(component =>
                    component.types.includes("country")
                )
                if (country.short_name === "TR") {
                    setIsMarkerShown(true)
                    setLocationCoordinates(selectedPoint)
                } else {
                    setIsMarkerShown(false)
                    setLocationCoordinates(null)
                    setError("Selected point is not in Turkey")
                }
            } else {
                console.log(
                    "Geocode was not successful for the following reason: " +
                        status
                )
            }
        })
    }

    // UseEffects

    // Binding
    const valueObject = {
        // States
        isMarkerShown,
        setIsMarkerShown,

        locationCoordinates,
        setLocationCoordinates,

        campaignLocationFormik,

        // Methods
        handleMapClick,
    }

    // Render
    return (
        <LocationsFormContext.Provider value={valueObject}>
            {children}
        </LocationsFormContext.Provider>
    )
}

export default LocationsFormProvider
