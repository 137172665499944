import React from "react"
import { useState } from "react"
import { Icons } from "../../utilities/Icons"
import styles from "../../styles/modules/components/Input.module.scss"
import InputMask from "react-input-mask"
import { t } from "i18next"

const Input = ({
    id = "",
    name = "",
    placeholder = "",
    type,
    value = "",
    onChange = () => {},
    disabled = false,
    className = "",
    onKeyDown = () => {},
    mask = "",
    maxLength = undefined,
}) => {
    // States and Hooks
    const [showPassword, setShowPassword] = useState(false)

    // Methods
    const onIconClick = e => {
        e.preventDefault()
        setShowPassword(!showPassword)
    }

    // Render
    return (
        <div className="position-relative">
            <InputMask
                id={id}
                name={name}
                type={showPassword ? "text" : type}
                disabled={disabled}
                onChange={onChange}
                value={value}
                className={className}
                placeholder={t(placeholder)}
                onKeyDown={onKeyDown}
                mask={mask}
                maxLength={maxLength}
            />
            {type === "password" && (
                <button
                    type="button"
                    className={`btn ${styles.button}`}
                    onClick={onIconClick}
                >
                    <div className={styles.icon}>
                        {showPassword
                            ? Icons.closePassword()
                            : Icons.showPassword()}
                    </div>
                </button>
            )}
        </div>
    )
}

export default Input
