import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { useApi } from "./ApiProvider"
import { TableViewTypes } from "./AppProvider"

const CampaignsContext = createContext()
export const useCampaigns = () => useContext(CampaignsContext)

const CampaignsProvider = ({ children }) => {
    // States and Hooks
    const { campaign, campaignOwner } = useApi()
    const perPage = 7
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isViewModalOpen, setIsViewModalOpen] = useState(false)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false)
    const [isResumeModalOpen, setIsResumeModalOpen] = useState(false)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const [isStartModalOpen, setIsStartModalOpen] = useState(false)
    const [createModalStep, setCreateModalStep] = useState(1)
    const [editModalStep, setEditModalStep] = useState(1)
    const [campaignValues, setCampaignValues] = useState(null)
    const [error, setError] = useState("")
    const [campaignOwnerItems, setCampaignOwnerItems] = useState(null)
    const [tableView, setTableView] = useState(TableViewTypes.main)
    const mapCenterLocation = { lat: 38.9637, lng: 35.2433 }

    // Methods
    const fetchCampaignOwners = async () => {
        const { data } = await campaignOwner.fetchActive()
        setCampaignOwnerItems(data.rows)
    }

    const fetchCampaigns = async () => {
        const response = await campaign.fetch({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        const campaignItems = response.data.rows
        setItems(campaignItems)
    }

    const fetchArchivedCampaigns = async () => {
        const response = await campaign.fetchArchived({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        const campaignItems = response.data.rows
        setItems(campaignItems)
    }

    const initialize = async () => {
        setLoading(true)
        await fetchCampaignOwners()
        if (tableView === TableViewTypes.main) await fetchCampaigns()
        if (tableView === TableViewTypes.archived)
            await fetchArchivedCampaigns()
        setLoading(false)
    }

    const cleanupCreateModal = () => {
        setError("")
        setCampaignValues(null)
        setCreateModalStep(1)
    }

    const cleanupEditModal = () => {
        setError("")
        setCampaignValues(null)
        setEditModalStep(1)
    }

    const closeCreateModal = () => {
        cleanupCreateModal()
        setIsCreateModalOpen(false)
    }

    const closeEditModal = () => {
        cleanupEditModal()
        setIsEditModalOpen(false)
    }

    const createModalBackStepHandler = () => {
        setError("")
        setCreateModalStep(currentStep => currentStep - 1)
    }

    const createModalNextStepHandler = () => {
        setError("")
        setCreateModalStep(currentStep => currentStep + 1)
    }

    const editModalBackStepHandler = () => {
        setError("")
        setEditModalStep(currentStep => currentStep - 1)
    }

    const editModalNextStepHandler = () => {
        setError("")
        setEditModalStep(currentStep => currentStep + 1)
    }

    // UseEffect
    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        setCurrentPage(1)
        initialize()
    }, [tableView])

    useEffect(() => {
        initialize()
    }, [currentPage])

    // Binding
    const valueObject = {
        // States
        perPage,

        loading,
        setLoading,

        items,
        setItems,

        currentPage,
        setCurrentPage,

        totalItems,
        setTotalItems,

        selectedItem,
        setSelectedItem,

        isCreateModalOpen,
        setIsCreateModalOpen,

        isEditModalOpen,
        setIsEditModalOpen,

        isViewModalOpen,
        setIsViewModalOpen,

        isArchiveModalOpen,
        setIsArchiveModalOpen,

        isPauseModalOpen,
        setIsPauseModalOpen,

        isResumeModalOpen,
        setIsResumeModalOpen,

        isCancelModalOpen,
        setIsCancelModalOpen,

        isStartModalOpen,
        setIsStartModalOpen,

        createModalStep,
        setCreateModalStep,

        editModalStep,
        setEditModalStep,

        campaignValues,
        setCampaignValues,

        error,
        setError,

        campaignOwnerItems,
        setCampaignOwnerItems,

        tableView,
        setTableView,

        mapCenterLocation,

        // Methods
        fetchCampaignOwners,
        fetchCampaigns,
        fetchArchivedCampaigns,
        initialize,
        cleanupCreateModal,
        closeCreateModal,
        closeEditModal,
        createModalBackStepHandler,
        createModalNextStepHandler,
        editModalBackStepHandler,
        editModalNextStepHandler,
    }

    // Render
    return (
        <CampaignsContext.Provider value={valueObject}>
            {children}
        </CampaignsContext.Provider>
    )
}

export default CampaignsProvider

export const CampaignStatuses = {
    Created: "Created",
    Started: "Started",
    Paused: "Paused",
    Finished: "Finished",
    Completed: "Completed",
    Canceled: "Canceled",
}

export const CampaignStatusesCheck = {
    Resumable: [CampaignStatuses.Paused],
    Archivable: [CampaignStatuses.Finished, CampaignStatuses.Canceled],
    Pausable: [CampaignStatuses.Started],
    Startable: [CampaignStatuses.Created],
    Cancelable: [
        CampaignStatuses.Created,
        CampaignStatuses.Started,
        CampaignStatuses.Paused,
        CampaignStatuses.Completed,
    ],
    Editable: [
        CampaignStatuses.Created,
        CampaignStatuses.Started,
        CampaignStatuses.Paused,
        CampaignStatuses.Completed,
    ],
    EditableStartDate: [CampaignStatuses.Created],
    EditableEndDate: [
        CampaignStatuses.Created,
        CampaignStatuses.Started,
        CampaignStatuses.Paused,
        CampaignStatuses.Completed,
    ],
}

export const CreateModalSteps = {
    Details: 1,
    UsageLimits: 2,
    Assignments: 3,
    Locations: 4,
    Codes: 5,
    Summary: 6,
}

export const EditModalSteps = {
    Details: 1,
    UsageLimits: 2,
    Assignments: 3,
    Locations: 4,
    Summary: 5,
}

export const CampaignUsageLimitTypes = {
    Daily: { label: "Daily", value: 1 },
    Weekly: { label: "Weekly", value: 2 },
    Monthly: { label: "Monthly", value: 3 },
    Yearly: { label: "Yearly", value: 4 },
    AllTime: { label: "All Time", value: 5 },
}
