import SideNav from "./SideNav"
import UnprotectedSideNav from "./UnprotectedSideNav"
import SideNavProvider from "../../providers/SideNavProvider"
import Header from "./Header"
import HeaderProvider from "../../providers/HeaderProvider"
import styles from "../../styles/modules/components/Layout.module.scss"
import { getUserToken } from "../../functions/getUserToken"
import { ProtectedRoute } from "./ProtectedRoute"
import { UnprotectedRoutes } from "../../utilities/AppRoutes"
import { Navigate } from "react-router"
import { AppRoutes } from "../../utilities/AppRoutes"

const Layout = ({ children }) => {
    return (
        <>
            <div className="row vh-100 m-0">
                <div className="d-none d-lg-block col-lg-2 p-0">
                    <SideNavProvider>
                        {UnprotectedRoutes.includes(
                            window.location.pathname
                        ) ? (
                            <UnprotectedSideNav />
                        ) : (
                            <SideNav />
                        )}
                    </SideNavProvider>
                </div>
                <div className="col-12 col-lg-10 p-0">
                    {!UnprotectedRoutes.includes(window.location.pathname) && (
                        <div className={`row m-0 ${styles.header}`}>
                            <HeaderProvider>
                                <Header />
                            </HeaderProvider>
                        </div>
                    )}
                    <div className={`row m-0 ${styles.content}`}>
                        {UnprotectedRoutes.includes(window.location.pathname) &&
                            getUserToken() && (
                                <Navigate to={AppRoutes.home} replace />
                            )}
                        {UnprotectedRoutes.includes(
                            window.location.pathname
                        ) ? (
                            children
                        ) : (
                            <ProtectedRoute>{children}</ProtectedRoute>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout
