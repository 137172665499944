import { axiosRequest } from "../axiosRequest"

export const CampaignOwnerController = {
    fetch: async ({ size, page }) =>
        await axiosRequest({
            url: "/campaign-owner/fetch",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    fetchActive: async () =>
        await axiosRequest({
            url: "/campaign-owner/fetch-active",
            method: "post",
            isProtected: true,
        }),
    fetchArchived: async ({ size, page }) =>
        await axiosRequest({
            url: "/campaign-owner/fetch-archived",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    create: async ({ organization, logo, isActive }) =>
        await axiosRequest({
            url: "/campaign-owner/create",
            method: "post",
            body: { organization, logo, is_active: isActive },
            isProtected: true,
        }),
    update: async ({ token, organization, logo }) =>
        await axiosRequest({
            url: "/campaign-owner/update",
            method: "post",
            body: { token, organization, logo },
            isProtected: true,
        }),
    activate: async ({ token }) =>
        await axiosRequest({
            url: "/campaign-owner/activate",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    disable: async ({ token }) =>
        await axiosRequest({
            url: "/campaign-owner/disable",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    archive: async ({ token }) =>
        await axiosRequest({
            url: "/campaign-owner/archive",
            method: "post",
            body: { token },
            isProtected: true,
        }),
}
