import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { toast } from "react-toastify"
import { useApi } from "../../../../providers/ApiProvider"
import { EditCampaignOwnerUserFormSchema } from "../../../../utilities/FormValidations"
import { CreateCampaignOwnerUserFormSchema } from "../../../../utilities/FormValidations"
import { useCampaignOwners } from "../../../../providers/CampaignOwnersProvider"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

const CampaignOwnerUsersContext = createContext()
export const useCampaignOwnerUsers = () => useContext(CampaignOwnerUsersContext)

const CampaignOwnerUsersProvider = ({ children }) => {
    // States and Hooks
    const { t } = useTranslation()
    const { campaignOwnerUser } = useApi()

    const { initialize, selectedItem, setSelectedItem } = useCampaignOwners()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [users, setUsers] = useState(null)
    const [activeUser, setActiveUser] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDeleteMode, setIsDeleteMode] = useState(false)
    const [isCreateMode, setIsCreateMode] = useState(false)

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
    }

    const onSubmitEditButtonClick = async () => {
        setError("")
        setLoading(true)

        const validationResponse =
            await EditCampaignOwnerUserFormSchema.validate({
                email: activeUser.email,
                password: activeUser.password,
            }).catch(error => setError(error.message))

        if (validationResponse) {
            const { data, error } = await campaignOwnerUser.update({
                token: activeUser.token,
                email: activeUser.email,
                password: activeUser.password,
            })
            if (error) setError(error)
            if (data) {
                const newSelectedItem = {
                    ...selectedItem,
                    users: selectedItem.users.map(user => {
                        if (user.token === activeUser.token)
                            return { ...data, campaign_owner: undefined }
                        return user
                    }),
                }
                setSelectedItem(newSelectedItem)
                await initialize()
                setIsEditMode(false)
                setIsDeleteMode(false)
                setActiveUser(null)
                toast.success(t("User edited successfully"))
            }
        }
        setLoading(false)
    }

    const onSubmitDeleteButtonClick = async () => {
        setError("")
        setLoading(true)

        const { data, error } = await campaignOwnerUser.delete({
            token: activeUser.token,
        })
        if (error) setError(error)
        if (data) {
            const newSelectedItem = {
                ...selectedItem,
                users: selectedItem.users.filter(user => {
                    if (user.token !== activeUser.token) return user
                }),
            }
            setSelectedItem(newSelectedItem)
            await initialize()
            setIsEditMode(false)
            setIsDeleteMode(false)
            setActiveUser(null)
            toast.success(t("User removed successfully"))
        }
        setLoading(false)
    }

    const clickSubmit = id => {
        document.getElementById(id).click()
    }

    const createCampaignOwnerUserFormik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (values, { resetForm }) => {
            setError("")
            setIsCreateMode(true)
            setLoading(true)

            const validationResponse =
                await CreateCampaignOwnerUserFormSchema.validate({
                    email: values.email,
                    password: values.password,
                }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await campaignOwnerUser.create({
                    campaign_owner_token: selectedItem.token,
                    email: values.email,
                    password: values.password,
                })
                if (error) setError(error)
                if (data) {
                    const newSelectedItem = {
                        ...selectedItem,
                        users: [
                            ...selectedItem.users,
                            {
                                ...data,
                                campaign_owner: undefined,
                            },
                        ],
                    }
                    setSelectedItem(newSelectedItem)
                    resetForm()
                    await initialize()
                    toast.success(t("User created successfully"))
                }
            }
            setIsCreateMode(false)
            setLoading(false)
        },
    })

    const resetTable = () => {
        setActiveUser(null)
        setIsEditMode(false)
        setIsDeleteMode(false)
    }

    // UseEffect
    useEffect(() => {
        if (selectedItem) setUsers(selectedItem.users)
    }, [selectedItem])

    // Binding
    const valueObject = {
        // States
        loading,
        setLoading,

        error,
        setError,

        users,
        setUsers,

        activeUser,
        setActiveUser,

        isEditMode,
        setIsEditMode,

        isDeleteMode,
        setIsDeleteMode,

        isCreateMode,
        setIsCreateMode,

        // Formik
        createCampaignOwnerUserFormik,

        // Methods
        formCleanup,
        onSubmitEditButtonClick,
        onSubmitDeleteButtonClick,
        clickSubmit,
        resetTable,
    }

    // Render
    return (
        <CampaignOwnerUsersContext.Provider value={valueObject}>
            {children}
        </CampaignOwnerUsersContext.Provider>
    )
}

export default CampaignOwnerUsersProvider
