import React from "react"
import { Form } from "react-bootstrap"
import Input from "../../../../template/Input"
import { CampaignUsageLimitTypes } from "../../../../../providers/CampaignsProvider"
import { Trans } from "react-i18next"

const FormFields = ({ formik }) => {
    return (
        <div className="row m-0">
            <div className="mb-3 col-12 col-sm-6 ps-sm-0 pe-sm-1">
                <label className="fw-500">
                    <Trans>Usage limit type</Trans>:
                </label>
                <Form.Select
                    id="usageLimitType"
                    name="usageLimitType"
                    disabled={formik.values.unlimited}
                    onChange={formik.handleChange}
                    value={
                        formik.values.unlimited
                            ? CampaignUsageLimitTypes.AllTime.label
                            : formik.values.usageLimitType
                    }
                    className="form-control rounded-0"
                >
                    <option value="">
                        <Trans>Select usage limit type</Trans>
                    </option>
                    {Object.values(CampaignUsageLimitTypes)?.map(item => {
                        return (
                            <option value={item.label} key={item.label}>
                                <Trans>{item.label}</Trans>
                            </option>
                        )
                    })}
                </Form.Select>
            </div>
            <div className="mb-3 col-12 col-sm-6 ps-sm-1 pe-sm-0">
                <label className="fw-500">
                    <Trans>Usage limit count</Trans>:
                </label>
                <Input
                    id="usageLimitCount"
                    name="usageLimitCount"
                    type="number"
                    disabled={formik.values.unlimited}
                    onChange={formik.handleChange}
                    value={
                        formik.values.unlimited
                            ? undefined
                            : formik.values.usageLimitCount
                    }
                    className="form-control rounded-0"
                    placeholder="Enter usage limit count"
                />
            </div>
            <div className="mb-3 col-12 col-sm-12 p-0 d-flex align-items-center gap-2">
                <label className="fw-500 no-select" htmlFor="status-checkbox">
                    <Trans>Unlimited</Trans>:
                </label>
                <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    id="unlimited"
                    name="unlimited"
                    onChange={formik.handleChange}
                    checked={formik.values.unlimited}
                />
            </div>
        </div>
    )
}

export default FormFields
