import React, { useContext, createContext } from "react"
import { FilterController } from "../api/controllers/FilterController"
import { CampaignOwnerController } from "../api/controllers/CampaignOwnerController"
import { CampaignOwnerUserController } from "../api/controllers/CampaignOwnerUserController"
import { AdminController } from "../api/controllers/AdminController"
import { UserController } from "../api/controllers/UserController"
import { SegmentController } from "../api/controllers/SegmentController"
import { ColorController } from "../api/controllers/ColorController"
import { CampaignController } from "../api/controllers/CampaignController"
import { DashboardController } from "../api/controllers/DashboardController"

const ApiContext = createContext()
export const useApi = () => useContext(ApiContext)

const ApiProvider = ({ children }) => {
    // States and Hooks

    // Methods

    // Binding
    const valueObject = {
        filter: { ...FilterController },
        campaignOwner: { ...CampaignOwnerController },
        campaignOwnerUser: { ...CampaignOwnerUserController },
        admin: { ...AdminController },
        user: { ...UserController },
        segment: { ...SegmentController },
        color: { ...ColorController },
        campaign: { ...CampaignController },
        dashboard: { ...DashboardController },
    }

    // Binding
    return (
        <ApiContext.Provider value={valueObject}>
            {children}
        </ApiContext.Provider>
    )
}

export default ApiProvider
