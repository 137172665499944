import React from "react"
import { Modal } from "react-bootstrap"
import { useCampaignOwners } from "../../../../providers/CampaignOwnersProvider"
import { useCampaignOwnerUsers } from "./CampaignOwnerUsersProvider"
import ErrorBox from "../../../template/ErrorBox"
import useScreen from "../../../../api/useScreen"
import CreateCampaignOwnerUserForm from "./CreateCampaignOwnerUserForm"
import CampaignOwnerUsersTable from "./CampaignOwnerUsersTable"
import { useTranslation } from "react-i18next"

const CampaignOwnerUsersModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { isMobile } = useScreen()
    const {
        isUsersModalOpen: isOpen,
        setIsUsersModalOpen: setIsOpen,
        selectedItem,
    } = useCampaignOwners()

    const { error, formCleanup } = useCampaignOwnerUsers()

    // Methods
    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fw-bold">{`${selectedItem.organization} ${t(
                        "Users"
                    )}`}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <img
                        src={selectedItem.logo}
                        alt="logo"
                        className="large-logo"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="custom-modal-footer">
                <ErrorBox error={error} />
                <CreateCampaignOwnerUserForm />
                <CampaignOwnerUsersTable />
            </Modal.Footer>
        </Modal>
    )
}

export default CampaignOwnerUsersModal
