import React, { useEffect } from "react"
import { useNavigate } from "react-router"
import { clearUserToken } from "../functions/clearUserToken"
import { AppRoutes } from "../utilities/AppRoutes"
import EllipsisLoader from "../components/template/EllipsisLoader"

const Logout = () => {
    // States and Hooks
    const navigator = useNavigate()

    // UseEffects
    useEffect(() => {
        clearUserToken()
        navigator(AppRoutes.login)
    }, [])

    return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <EllipsisLoader dark />
        </div>
    )
}

export default Logout
