import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useUsers } from "../../../providers/UsersProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import Select from "react-select"
import { isDarkColor } from "../../../functions/isDarkColor"
import { Trans, useTranslation } from "react-i18next"

const UserSegmentsModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { user, segment } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)
    const [error, setError] = useState("")
    const [segmentItems, setSegmentItems] = useState(null)
    const {
        isSegmentsModalOpen: isOpen,
        setIsSegmentsModalOpen: setIsOpen,
        initialize,
        selectedItem,
        setSelectedItem,
    } = useUsers()

    const initialUserSegmentItems = selectedItem.segments.map(
        ({ token, title, color }) => ({
            value: token,
            label: title,
            color,
        })
    )
    const [userSegmentItems, setUserSegmentItems] = useState(
        initialUserSegmentItems
    )

    const colorStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
                ...(isDarkColor(data.color) && { color: "white" }),
            }
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
            }
        },
        multiValueLabel: (styles, { data }) => {
            return {
                ...styles,
                ...(isDarkColor(data.color) && { color: "white" }),
            }
        },
    }

    const noOptionsMessageHandler = () => t("No segments")

    const userSegmentItemsHandler = async newUserSegmentItems => {
        const removedSegment = userSegmentItems.filter(
            x => !newUserSegmentItems.includes(x)
        )[0]

        const addedSegment = newUserSegmentItems.filter(
            x => !userSegmentItems.includes(x)
        )[0]

        if (removedSegment) {
            setError("")
            setActionLoading(true)

            const { data, error } = await user.deleteSegment({
                userToken: selectedItem.token,
                segmentToken: removedSegment.value,
            })

            if (error) toast.error(t("Something went wrong"))
            if (data) {
                setUserSegmentItems(newUserSegmentItems)
                initialize()
            }

            setActionLoading(false)
        }

        if (addedSegment) {
            setError("")
            setActionLoading(true)

            const { data, error } = await user.addSegment({
                userToken: selectedItem.token,
                segmentToken: addedSegment.value,
            })

            if (error) toast.error(t("Something went wrong"))
            if (data) {
                setUserSegmentItems(newUserSegmentItems)
                initialize()
            }

            setActionLoading(false)
        }
    }

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
        setSelectedItem(null)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    const fetchSegmentItems = async () => {
        setLoading(true)
        const { data, error } = await segment.fetchActive()
        if (error) toast.error(t("Something went wrong"))
        if (data) {
            setSegmentItems(
                data.rows.map(({ token, title, color }) => ({
                    value: token,
                    label: title,
                    color,
                }))
            )
        }
        setLoading(false)
    }

    // UseEffects
    useEffect(() => {
        fetchSegmentItems()
    }, [])

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "md"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Trans>User Segments</Trans>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <EllipsisLoader dark />}
                {!loading && (
                    <>
                        <ErrorBox error={error} />
                        <Select
                            defaultValue={userSegmentItems}
                            isDisabled={loading || actionLoading}
                            isLoading={actionLoading}
                            value={userSegmentItems}
                            onChange={userSegmentItemsHandler}
                            options={segmentItems}
                            styles={colorStyles}
                            className="basic-multi-select"
                            classNamePrefix="user-segments-select"
                            placeholder={t("Select segments")}
                            isClearable={false}
                            isMulti
                            noOptionsMessage={noOptionsMessageHandler}
                        />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="col-lg-3 col-12">
                    <button
                        className="btn btn-outline-dark fw-600 rounded-0 w-100"
                        onClick={closeModal}
                    >
                        <Trans>Close</Trans>
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default UserSegmentsModal
