export const isDarkColor = color => {
    const rgb = parseInt(color.substring(1), 16)
    const red = (rgb >> 16) & 0xff
    const green = (rgb >> 8) & 0xff
    const blue = (rgb >> 0) & 0xff
    const brightness = 0.2126 * red + 0.7152 * green + 0.0722 * blue

    if (brightness < 200) return true

    return false
}
