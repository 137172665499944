import React from "react"
import Layout from "../components/template/Layout"
import UsersTable from "../components/page-components/users/UsersTable"
import ArchivedUsersTable from "../components/page-components/users/ArchivedUsersTable"
import { useUsers } from "../providers/UsersProvider"
import CreateUserModal from "../components/page-components/users/CreateUserModal"
import EditUserModal from "../components/page-components/users/EditUserModal"
import ArchiveUserModal from "../components/page-components/users/ArchiveUserModal"
import UserSegmentsModal from "../components/page-components/users/UserSegmentsModal"
import { Icons } from "../utilities/Icons"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { TableViewTypes } from "../providers/AppProvider"
import { Trans, useTranslation } from "react-i18next"

const Users = () => {
    const { t } = useTranslation()
    const {
        isCreateModalOpen,
        setIsCreateModalOpen,
        isEditModalOpen,
        isArchiveModalOpen,
        isSegmentsModalOpen,
        setTableView,
        loading,
        tableView,
    } = useUsers()

    const disabledMainTable = tableView === TableViewTypes.archived && loading
    const disabledArchivedTable = tableView === TableViewTypes.main && loading

    const openCreateModal = () => setIsCreateModalOpen(true)

    return (
        <Layout>
            <div className="p-4">
                <button
                    className="btn btn-primary mb-3 d-flex align-items-center"
                    onClick={openCreateModal}
                >
                    <div className="small-icon">{Icons.add()}</div>
                    <div>
                        <Trans>Add User</Trans>
                    </div>
                </button>

                {isCreateModalOpen && <CreateUserModal />}
                {isEditModalOpen && <EditUserModal />}
                {isArchiveModalOpen && <ArchiveUserModal />}
                {isSegmentsModalOpen && <UserSegmentsModal />}

                <Tabs
                    defaultActiveKey="main"
                    className="fw-bold mb-3 table-tabs"
                    onSelect={eventKey => {
                        setTableView(eventKey)
                    }}
                    fill
                >
                    <Tab
                        eventKey={TableViewTypes.main}
                        title={t("Main")}
                        disabled={disabledMainTable}
                    >
                        <UsersTable />
                    </Tab>
                    <Tab
                        eventKey={TableViewTypes.archived}
                        title={t("Archived")}
                        disabled={disabledArchivedTable}
                    >
                        <ArchivedUsersTable />
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    )
}

export default Users
