import React from "react"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Layout from "../components/template/Layout"
import { Icons } from "../utilities/Icons"
import { useCampaigns } from "../providers/CampaignsProvider"
import CreateModal from "../components/page-components/campaigns/create/CreateModal"
import EditModal from "../components/page-components/campaigns/edit/EditModal"
import ViewModal from "../components/page-components/campaigns/view/ViewModal"
import ArchiveModal from "../components/page-components/campaigns/ArchiveModal"
import PauseModal from "../components/page-components/campaigns/PauseModal"
import ResumeModal from "../components/page-components/campaigns/ResumeModal"
import CancelModal from "../components/page-components/campaigns/CancelModal"
import StartModal from "../components/page-components/campaigns/StartModal"
import MainTable from "../components/page-components/campaigns/table/main-table/MainTable"
import ArchivedTable from "../components/page-components/campaigns/table/archived-table/ArchivedTable"
import { TableViewTypes } from "../providers/AppProvider"
import { Trans, useTranslation } from "react-i18next"

const Campaigns = () => {
    const { t } = useTranslation()

    const {
        isCreateModalOpen,
        setIsCreateModalOpen,
        isEditModalOpen,
        isViewModalOpen,
        isArchiveModalOpen,
        isPauseModalOpen,
        isResumeModalOpen,
        isCancelModalOpen,
        isStartModalOpen,
        setTableView,
        loading,
        tableView,
    } = useCampaigns()

    const disabledMainTable = tableView === TableViewTypes.archived && loading
    const disabledArchivedTable = tableView === TableViewTypes.main && loading

    const openCreateModal = () => setIsCreateModalOpen(true)

    return (
        <Layout>
            <div className="p-4">
                <button
                    className="btn btn-primary mb-3 d-flex align-items-center"
                    onClick={openCreateModal}
                >
                    <div className="small-icon">{Icons.add()}</div>
                    <div>
                        <Trans>Add Campaign</Trans>
                    </div>
                </button>

                {isCreateModalOpen && <CreateModal />}
                {isEditModalOpen && <EditModal />}
                {isViewModalOpen && <ViewModal />}
                {isArchiveModalOpen && <ArchiveModal />}
                {isPauseModalOpen && <PauseModal />}
                {isResumeModalOpen && <ResumeModal />}
                {isCancelModalOpen && <CancelModal />}
                {isStartModalOpen && <StartModal />}

                <Tabs
                    defaultActiveKey="main"
                    className="fw-bold mb-3 table-tabs"
                    onSelect={eventKey => setTableView(eventKey)}
                    fill
                >
                    <Tab
                        eventKey={TableViewTypes.main}
                        title={t("Main")}
                        disabled={disabledMainTable}
                    >
                        <MainTable />
                    </Tab>
                    <Tab
                        eventKey={TableViewTypes.archived}
                        title={t("Archived")}
                        disabled={disabledArchivedTable}
                    >
                        <ArchivedTable />
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    )
}

export default Campaigns
