import React from "react"
import { useCampaignOwnerUsers } from "./CampaignOwnerUsersProvider"
import { Icons } from "../../../../utilities/Icons"
import PasswordContainer from "../../../template/PasswordContainer"
import CopyToClipboard from "../../../template/CopyToClipboard"
import { Trans } from "react-i18next"

const CampaignOwnerUserItem = ({ user }) => {
    const { loading, setActiveUser, setIsEditMode, setIsDeleteMode } =
        useCampaignOwnerUsers()

    const activateEditMode = () => {
        setActiveUser(user)
        setIsEditMode(true)
        setIsDeleteMode(false)
    }
    const activateDeleteMode = () => {
        setActiveUser(user)
        setIsDeleteMode(true)
        setIsEditMode(false)
    }

    return (
        <>
            <td>{user.email}</td>
            <td>
                <div className="d-flex align-items-center gap-1 w-100">
                    <PasswordContainer value={user.password} />
                    <CopyToClipboard text={user.password} />
                </div>
            </td>
            <td>
                <div className="d-flex flex-column flex-lg-row justify-content-lg-end gap-lg-1">
                    <button
                        className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-2 border-0 custom-user-button"
                        type="button"
                        disabled={loading}
                        onClick={activateEditMode}
                    >
                        <div className="crud-icon">{Icons.edit()}</div>
                        <div className="mt-1px">
                            <Trans>Edit</Trans>
                        </div>
                    </button>
                    <button
                        className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-2 border-0 custom-user-button"
                        type="button"
                        disabled={loading}
                        onClick={activateDeleteMode}
                    >
                        <div className="crud-icon">{Icons.userRemove()}</div>
                        <div className="mt-1px">
                            <Trans>Remove</Trans>
                        </div>
                    </button>
                </div>
            </td>
        </>
    )
}

export default CampaignOwnerUserItem
