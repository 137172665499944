import React from "react"
import { Icons } from "../../utilities/Icons"
import { Trans } from "react-i18next"

const ErrorBox = ({ error }) => {
    return (
        error && (
            <div className="col-12 p-0 my-2">
                <div className="text-danger d-flex align-items-center gap-2">
                    <div className="error-icon">{Icons.error()}</div>
                    <div className="text-sentence fw-600">
                        <Trans>{error}</Trans>
                    </div>
                </div>
            </div>
        )
    )
}

export default ErrorBox
