import { useTranslation } from "react-i18next"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useApi } from "./ApiProvider"
import { UnprotectedRoutes } from "../utilities/AppRoutes"

const AppContext = createContext()
export const useApp = () => useContext(AppContext)

const AppProvider = ({ children }) => {
    // States and Hooks
    const { admin } = useApi()
    const { i18n } = useTranslation()
    const [loadingApp, setLoadingApp] = useState(true)
    const [currentItem, setCurrentItem] = useState(null)
    const [currentAdmin, setCurrentAdmin] = useState(null)

    // Methods
    const changeLanguage = lang => i18n.changeLanguage(lang)

    const fetchCurrentAdmin = async () => {
        const { data } = await admin.fetchCurrent()
        if (data) setCurrentAdmin(data)
    }

    const initialize = async () => {
        const { pathname } = window.location
        if (!UnprotectedRoutes.includes(pathname)) await fetchCurrentAdmin()
        setLoadingApp(false)
    }

    // UseEffects
    useEffect(() => {
        initialize()
    }, [])

    // Binding
    const valueObject = {
        // States and Hooks
        loadingApp,
        setLoadingApp,

        currentItem,
        setCurrentItem,

        currentAdmin,
        setCurrentAdmin,

        // Methods
        changeLanguage,
        fetchCurrentAdmin,
    }

    // Render
    return (
        <AppContext.Provider value={valueObject}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider

export const CampaignStatus = {
    Expired: "expired",
    Full: "full",
}

export const TableViewTypes = { main: "main", archived: "archived" }
