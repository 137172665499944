import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/index.scss"
import "rc-pagination/assets/index.css"
import "react-toastify/dist/ReactToastify.css"
import "react-datepicker/dist/react-datepicker.css"
import "react-quill/dist/quill.snow.css"
import "swiper/css"
import "./i18n"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

reportWebVitals()
