import React from "react"
import { Images } from "../../utilities/Images"
import { Trans } from "react-i18next"

const NoData = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 py-3">
            <img src={Images.NoDataImage} className="img-fluid" alt="No data" />
            <h6 className="text-dark fw-500">
                <Trans>No Data</Trans>
            </h6>
        </div>
    )
}

export default NoData
