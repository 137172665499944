import React from "react"
import { Images } from "../../utilities/Images"
import styles from "../../styles/modules/components/SideNav.module.scss"
import { SideNavLinks } from "../../providers/SideNavProvider"
import { Link } from "react-router-dom"
import { Trans } from "react-i18next"
import { UnprotectedRoutes } from "../../utilities/AppRoutes"

const UnprotectedSideNav = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={Images.Logo} alt="logo" className="img-fluid" />
            </div>
            <div className={styles.links}>
                {SideNavLinks.map((item, index) => {
                    return (
                        UnprotectedRoutes.includes(item.value) && (
                            <Link to={item.value} key={index}>
                                <div className={styles.link}>
                                    <div
                                        className={`${
                                            styles["icon-container"]
                                        } ${
                                            window.location.pathname ===
                                                item.value &&
                                            styles["active-icon"]
                                        }`}
                                    >
                                        <svg className={styles.icon}>
                                            {item.icon}
                                        </svg>
                                    </div>
                                    <div
                                        className={`${styles.label} ${
                                            window.location.pathname ===
                                                item.value &&
                                            styles["active-label"]
                                        }`}
                                    >
                                        <Trans>{item.label}</Trans>
                                    </div>
                                </div>
                            </Link>
                        )
                    )
                })}
            </div>
        </div>
    )
}

export default UnprotectedSideNav
