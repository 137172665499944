import React from "react"
import Dropdown from "react-bootstrap/Dropdown"
import {
    useCampaigns,
    CampaignStatusesCheck,
} from "../../../../../providers/CampaignsProvider"
import formatDateTimeValue from "../../../../../functions/formatDateTimeValue"
import { Icons } from "../../../../../utilities/Icons"
import TableButton from "../../../../template/TableButton"
import ColorSegment from "../../../../template/ColorSegment"
import { Trans } from "react-i18next"

const TableBody = () => {
    // States and Hooks
    const {
        perPage,
        items,
        setIsViewModalOpen,
        setSelectedItem,
        setCampaignValues,
        setIsArchiveModalOpen,
        setIsPauseModalOpen,
        setIsResumeModalOpen,
        setIsCancelModalOpen,
        setIsEditModalOpen,
        setIsStartModalOpen,
    } = useCampaigns()

    // Methods
    const viewButtonClickHandler = item => {
        setSelectedItem(item)
        setIsViewModalOpen(true)
    }

    const editButtonClickHandler = item => {
        setSelectedItem(item)
        setIsEditModalOpen(true)
    }

    const openArchiveModal = () => setIsArchiveModalOpen(true)
    const openPauseModal = () => setIsPauseModalOpen(true)
    const openResumeModal = () => setIsResumeModalOpen(true)
    const openCancelModal = () => setIsCancelModalOpen(true)
    const openStartModal = () => setIsStartModalOpen(true)

    return (
        <>
            {items?.length > 0 &&
                items.map((item, index) => {
                    const archivable =
                        CampaignStatusesCheck.Archivable.includes(
                            item.status.name
                        )

                    const pausable = CampaignStatusesCheck.Pausable.includes(
                        item.status.name
                    )

                    const resumable = CampaignStatusesCheck.Resumable.includes(
                        item.status.name
                    )

                    const cancelable =
                        CampaignStatusesCheck.Cancelable.includes(
                            item.status.name
                        )

                    const editable = CampaignStatusesCheck.Editable.includes(
                        item.status.name
                    )

                    const startable = CampaignStatusesCheck.Startable.includes(
                        item.status.name
                    )

                    return (
                        <tr key={index} className="bg-white">
                            <td>
                                <div className="d-flex gap-2 align-items-center">
                                    <img
                                        src={item?.campaign_owner?.logo}
                                        alt="campaignOwnerLogo"
                                        className="large-icon"
                                    />
                                    <div>
                                        {item?.campaign_owner?.organization}
                                    </div>
                                </div>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.capacity}</td>
                            <td>
                                {formatDateTimeValue(
                                    new Date(item?.start_date)
                                )}
                            </td>
                            <td>
                                {formatDateTimeValue(new Date(item?.end_date))}
                            </td>
                            <td>
                                <ColorSegment
                                    text={item.status.name}
                                    color={item.status.color}
                                />
                            </td>
                            <td className="text-end">
                                <Dropdown className="actions-dropdown">
                                    <Dropdown.Toggle variant="outlined">
                                        <div className="small-icon">
                                            {Icons.ellipsis()}
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {!archivable && (
                                            <Dropdown.Item className="p-0">
                                                <TableButton
                                                    onClick={() => {
                                                        setSelectedItem(item)
                                                        openStartModal()
                                                    }}
                                                    disabled={!startable}
                                                    tooltip={
                                                        !startable
                                                            ? "Only created campaigns could be started"
                                                            : ""
                                                    }
                                                    icon={Icons.play()}
                                                    inline={false}
                                                >
                                                    <Trans>Start</Trans>
                                                </TableButton>
                                            </Dropdown.Item>
                                        )}

                                        {!resumable && !archivable && (
                                            <Dropdown.Item className="p-0">
                                                <TableButton
                                                    onClick={() => {
                                                        setSelectedItem(item)
                                                        openPauseModal()
                                                    }}
                                                    disabled={!pausable}
                                                    tooltip={
                                                        !pausable
                                                            ? "Only started campaigns could be paused"
                                                            : ""
                                                    }
                                                    icon={Icons.playPause()}
                                                    inline={false}
                                                >
                                                    <Trans>Pause</Trans>
                                                </TableButton>
                                            </Dropdown.Item>
                                        )}

                                        {resumable && !archivable && (
                                            <Dropdown.Item className="p-0">
                                                <TableButton
                                                    onClick={() => {
                                                        setSelectedItem(item)
                                                        openResumeModal()
                                                    }}
                                                    disabled={!resumable}
                                                    tooltip={
                                                        !resumable
                                                            ? "Only paused campaigns could be resumed"
                                                            : ""
                                                    }
                                                    icon={Icons.playPause()}
                                                    inline={false}
                                                >
                                                    <Trans>Resume</Trans>
                                                </TableButton>
                                            </Dropdown.Item>
                                        )}

                                        {!archivable && (
                                            <Dropdown.Item className="p-0">
                                                <TableButton
                                                    onClick={() => {
                                                        editButtonClickHandler(
                                                            item
                                                        )
                                                    }}
                                                    disabled={!editable}
                                                    tooltip={
                                                        !editable
                                                            ? "Finished or canceled campaigns could not be edited"
                                                            : ""
                                                    }
                                                    icon={Icons.edit()}
                                                    inline={false}
                                                >
                                                    <Trans>Edit</Trans>
                                                </TableButton>
                                            </Dropdown.Item>
                                        )}

                                        {!archivable && (
                                            <Dropdown.Item className="p-0">
                                                <TableButton
                                                    onClick={() => {
                                                        setSelectedItem(item)
                                                        openCancelModal()
                                                    }}
                                                    disabled={!cancelable}
                                                    tooltip={
                                                        !cancelable
                                                            ? "Only active campaigns could be canceled"
                                                            : ""
                                                    }
                                                    icon={Icons.close()}
                                                    inline={false}
                                                >
                                                    <Trans>Cancel</Trans>
                                                </TableButton>
                                            </Dropdown.Item>
                                        )}

                                        <Dropdown.Item className="p-0">
                                            <TableButton
                                                onClick={() => {
                                                    viewButtonClickHandler(item)
                                                }}
                                                icon={Icons.showPassword()}
                                                inline={false}
                                            >
                                                <Trans>View</Trans>
                                            </TableButton>
                                        </Dropdown.Item>

                                        <Dropdown.Item className="p-0">
                                            <TableButton
                                                onClick={() => {
                                                    setSelectedItem(item)
                                                    openArchiveModal()
                                                }}
                                                disabled={!archivable}
                                                tooltip={
                                                    !archivable
                                                        ? "Only finished or canceled campaigns could be archived"
                                                        : ""
                                                }
                                                icon={Icons.archive()}
                                                inline={false}
                                            >
                                                <Trans>Archive</Trans>
                                            </TableButton>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )
                })}
            {items?.length > 0 &&
                items.length !== perPage &&
                Array.from(Array(perPage - items.length).keys()).map(
                    (item, index) => {
                        return (
                            <tr
                                key={index}
                                className="invisible border-1 border-light bg-light h-50px"
                            >
                                <td className="fw-bold" colSpan={7}>
                                    Data
                                </td>
                            </tr>
                        )
                    }
                )}
        </>
    )
}

export default TableBody
