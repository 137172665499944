import { axiosRequest } from "../axiosRequest"

export const CampaignController = {
    create: async ({
        name,
        startDate,
        endDate,
        usageLimitCount,
        usageLimitType,
        description,
        hasFeedback,
        campaignOwnerToken,
        filterTokens,
        segmentTokens,
        locations,
        codes,
        thumbnails,
    }) =>
        await axiosRequest({
            url: "/campaign/create",
            method: "post",
            body: {
                name,
                start_date: startDate,
                end_date: endDate,
                usage_limit_count: usageLimitCount,
                usage_limit_type: usageLimitType,
                description,
                has_feedback: hasFeedback,
                campaign_owner_token: campaignOwnerToken,
                filter_tokens: filterTokens,
                segment_tokens: segmentTokens,
                locations,
                codes,
                thumbnails,
            },
            isProtected: true,
        }),
    update: async ({
        token,
        name,
        startDate,
        endDate,
        usageLimitCount,
        usageLimitType,
        description,
        hasFeedback,
        campaignOwnerToken,
        filterTokens,
        segmentTokens,
        locations,
        thumbnails,
    }) =>
        await axiosRequest({
            url: "/campaign/update",
            method: "post",
            body: {
                token,
                name,
                start_date: startDate,
                end_date: endDate,
                usage_limit_count: usageLimitCount,
                usage_limit_type: usageLimitType,
                description,
                has_feedback: hasFeedback,
                campaign_owner_token: campaignOwnerToken,
                filter_tokens: filterTokens,
                segment_tokens: segmentTokens,
                locations,
                thumbnails,
            },
            isProtected: true,
        }),
    fetch: async ({ size, page }) =>
        await axiosRequest({
            url: "/campaign/fetch",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    fetchActive: async ({ size, page }) =>
        await axiosRequest({
            url: "/campaign/fetch-active",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    fetchCodes: async ({ token }) =>
        await axiosRequest({
            url: "/campaign/fetch-codes",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    archive: async ({ token }) =>
        await axiosRequest({
            url: "/campaign/archive",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    pause: async ({ token }) =>
        await axiosRequest({
            url: "/campaign/pause",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    resume: async ({ token }) =>
        await axiosRequest({
            url: "/campaign/resume",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    cancel: async ({ token }) =>
        await axiosRequest({
            url: "/campaign/cancel",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    start: async ({ token }) =>
        await axiosRequest({
            url: "/campaign/start",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    fetchArchived: async ({ size, page }) =>
        await axiosRequest({
            url: "/campaign/fetch-archived",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
}
