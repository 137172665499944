// Dependencies
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import AdminsPageContent from "./content/Admins.json"
import CampaignOwnersPageContent from "./content/CampaignOwners.json"
import CampaignsPageContent from "./content/Campaigns.json"
import FiltersPageContent from "./content/Filters.json"
import HomePageContent from "./content/Home.json"
import LoginPageContent from "./content/Login.json"
import LogoutActionsPageContent from "./content/LogoutActions.json"
import SegmentsPageContent from "./content/Segments.json"
import UsersPageContent from "./content/Users.json"

i18n.use(initReactI18next).init({
    // Language initialization
    resources: {
        en: {
            translations: {
                ...AdminsPageContent.en,
                ...CampaignOwnersPageContent.en,
                ...CampaignsPageContent.en,
                ...FiltersPageContent.en,
                ...HomePageContent.en,
                ...LoginPageContent.en,
                ...LogoutActionsPageContent.en,
                ...SegmentsPageContent.en,
                ...UsersPageContent.en,
            },
        },
        tr: {
            translations: {
                ...AdminsPageContent.tr,
                ...CampaignOwnersPageContent.tr,
                ...CampaignsPageContent.tr,
                ...FiltersPageContent.tr,
                ...HomePageContent.tr,
                ...LoginPageContent.tr,
                ...LogoutActionsPageContent.tr,
                ...SegmentsPageContent.tr,
                ...UsersPageContent.tr,
            },
        },
    },
    fallbackLng: "en",
    debug: false, // TODO: Change this to false for production.

    // Have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false,
    },
})

export default i18n
