import React, { createContext, useContext, useEffect, useState } from "react"
import { AppRoutes } from "../utilities/AppRoutes"
import { Icons } from "../utilities/Icons"

const SideNavContext = createContext()
export const useSideNav = () => useContext(SideNavContext)

const SideNavProvider = ({ children }) => {
    // States & Hooks
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    // Methods
    const openMenu = () => {
        setIsMenuOpen(true)
    }
    const closeMenu = () => {
        setIsMenuOpen(false)
    }

    // UseEffects
    useEffect(() => {}, [])

    // Binding
    const valueObject = {
        // States
        isMenuOpen,
        setIsMenuOpen,

        // Methods
        openMenu,
        closeMenu,
    }

    // Render
    return (
        <SideNavContext.Provider value={valueObject}>
            {children}
        </SideNavContext.Provider>
    )
}

export default SideNavProvider

export const SideNavLinks = [
    {
        label: "Login",
        value: AppRoutes.login,
        icon: Icons.login(),
    },
    {
        label: "Dashboard",
        value: AppRoutes.home,
        icon: Icons.home(),
    },
    {
        label: "Campaigns",
        value: AppRoutes.campaigns,
        icon: Icons.campaign(),
    },
    {
        label: "Campaign Owners",
        value: AppRoutes.campaignOwners,
        icon: Icons.speaker(),
    },
    {
        label: "Filters",
        value: AppRoutes.filters,
        icon: Icons.filter(),
    },
    {
        label: "Segments",
        value: AppRoutes.segments,
        icon: Icons.tag(),
    },
    {
        label: "Users",
        value: AppRoutes.users,
        icon: Icons.group(),
    },
    {
        label: "Admins",
        value: AppRoutes.admins,
        icon: Icons.group(),
    },
    {
        label: "Exit",
        value: AppRoutes.logoutActions,
        icon: Icons.exit(),
    },
]
