import React from "react"
import { useCampaignOwnerUsers } from "./CampaignOwnerUsersProvider"
import EllipsisLoader from "../../../template/EllipsisLoader"
import EditCampaignOwnerUserForm from "./EditCampaignOwnerUserForm"
import DeleteCampaignOwnerUserFrom from "./DeleteCampaignOwnerUserFrom"
import CampaignOwnerUserItem from "./CampaignOwnerUserItem"
import NoData from "../../../template/NoData"
import { Trans } from "react-i18next"

const CampaignOwnerUsersTable = () => {
    const { loading, users, activeUser, isEditMode, isDeleteMode } =
        useCampaignOwnerUsers()
    return (
        <table className="table align-middle overflow-hidden rounded-top">
            <thead className="bg-white table-primary text-white">
                <tr>
                    <th>
                        <Trans>Email</Trans>
                    </th>
                    <th>
                        <Trans>Password</Trans>
                    </th>
                    <th className="text-end">
                        <Trans>Actions</Trans>
                    </th>
                </tr>
            </thead>
            <tbody>
                {(!users || users?.length === 0) && (
                    <tr className="text-center">
                        <td className="fw-600" colSpan={3}>
                            <div className="w-25 mx-auto">
                                <NoData />
                            </div>
                        </td>
                    </tr>
                )}

                {users?.length > 0 &&
                    users.map((user, index) => {
                        return (
                            <>
                                {loading &&
                                activeUser?.token === user?.token ? (
                                    <tr>
                                        <td colSpan={3}>
                                            <EllipsisLoader dark />
                                        </td>
                                    </tr>
                                ) : (
                                    <tr key={index} className="bg-white">
                                        {activeUser?.token === user.token ? (
                                            <>
                                                {isEditMode && (
                                                    <EditCampaignOwnerUserForm
                                                        user={user}
                                                    />
                                                )}
                                                {isDeleteMode && (
                                                    <DeleteCampaignOwnerUserFrom
                                                        user={user}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <CampaignOwnerUserItem
                                                user={user}
                                            />
                                        )}
                                    </tr>
                                )}
                            </>
                        )
                    })}
            </tbody>
        </table>
    )
}

export default CampaignOwnerUsersTable
