import React from "react"
import Pagination from "rc-pagination"
import { Link } from "react-router-dom"
import { useDashboard } from "../../../../providers/DashboardProvider"
import TableBody from "./TableBody"
import TableHead from "./TableHead"
import TableNoData from "../../../template/TableNoData"
import { AppRoutes } from "../../../../utilities/AppRoutes"
import { Icons } from "../../../../utilities/Icons"
import EllipsisLoader from "../../../template/EllipsisLoader"
import { Trans } from "react-i18next"

const ActiveCampaignsTable = () => {
    const {
        activeCampaignsTableLoading: loading,
        activeCampaignsPerPage: perPage,
        activeCampaignsCurrentPage: currentPage,
        setActiveCampaignsCurrentPage: setCurrentPage,
        activeCampaignsTotalItems: totalItems,
        activeCampaignsItems: items,
    } = useDashboard()

    return (
        <div className="w-100 p-2">
            <div className="w-100 bg-light">
                <div className="d-flex bg-darkgray rounded-2 text-white p-2 px-3 ps-0 align-items-center mb-2">
                    <div className="medium-icon mx-3">{Icons.check()}</div>
                    <div className="fw-bold m-0">
                        <Trans>Active Campaigns</Trans>
                    </div>
                    <Link to={AppRoutes.campaigns} className="ms-auto">
                        <Trans>View all</Trans>
                    </Link>
                </div>
                <table className="table align-middle overflow-hidden rounded-top">
                    <TableHead />

                    {loading && (
                        <tr style={{ height: `${perPage * 53}px` }}>
                            <td colSpan={6}>
                                <EllipsisLoader dark />
                            </td>
                        </tr>
                    )}

                    {!loading && (
                        <tbody>
                            <TableNoData items={items} colspan={6} />
                            <TableBody />
                        </tbody>
                    )}
                </table>
            </div>

            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default ActiveCampaignsTable
