import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { useFilters } from "../../../providers/FiltersProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import { FiltersEditSchema } from "../../../utilities/FormValidations"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import { useTranslation, Trans } from "react-i18next"

const EditFilterModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { filter } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isEditModalOpen: isOpen,
        setIsEditModalOpen: setIsOpen,
        initialize,
        selectedItem,
        setSelectedItem,
    } = useFilters()

    const editFilterFormik = useFormik({
        initialValues: {
            name: selectedItem.name,
            order: selectedItem.order,
        },
        onSubmit: async values => {
            setError("")
            setLoading(true)
            const validationResponse = await FiltersEditSchema.validate({
                name: values.name,
                order: values.order,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await filter.update({
                    token: selectedItem.token,
                    name: values.name,
                    order: values.order,
                })
                if (error) setError(error)
                if (data) {
                    closeModal()
                    await initialize()
                    toast.success(t("Filter edited successfully"))
                }
            }
            setLoading(false)
        },
    })

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
        setSelectedItem(null)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={editFilterFormik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Edit Filter</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Name</Trans>:
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            disabled={loading}
                            onChange={editFilterFormik.handleChange}
                            value={editFilterFormik.values.name}
                            className="form-control rounded-0"
                            placeholder="Name"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Order</Trans>:
                        </label>
                        <input
                            id="order"
                            name="order"
                            type="number"
                            disabled={loading}
                            onChange={editFilterFormik.handleChange}
                            value={editFilterFormik.values.order}
                            className="form-control rounded-0"
                            placeholder="Order"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                            type="button"
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Update</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditFilterModal
