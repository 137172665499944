import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { Icons } from "../../../../../utilities/Icons"
import { Trans } from "react-i18next"

const LocationsTable = ({ actions = true }) => {
    // States and Hooks
    const { campaignValues, setCampaignValues } = useCampaigns()

    // Methods
    const locationDeleteHandler = removedLocationIndex => {
        const newCampaignLocations = campaignValues.locations.filter(
            (_location, index) => index !== removedLocationIndex
        )
        setCampaignValues({
            ...campaignValues,
            locations: newCampaignLocations,
        })
    }

    // UseEffects

    // Render
    return (
        <table className="table align-middle overflow-hidden rounded-top">
            <thead className="bg-white table-primary text-white">
                <tr>
                    <th>
                        <Trans>Name</Trans>
                    </th>
                    <th>
                        <Trans>Latitude</Trans>
                    </th>
                    <th>
                        <Trans>Longitude</Trans>
                    </th>
                    {actions && (
                        <th>
                            <Trans>Actions</Trans>
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {campaignValues.locations.map((location, index) => {
                    return (
                        <tr className="bg-white" key={index}>
                            <td>{location?.name}</td>
                            <td>{location?.lat.toFixed(5)}</td>
                            <td>{location?.lng.toFixed(5)}</td>
                            {actions && (
                                <td>
                                    <div className="d-flex justify-content-end gap-1">
                                        <button
                                            className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-2"
                                            onClick={() => {
                                                locationDeleteHandler(index)
                                            }}
                                        >
                                            <div className="crud-icon">
                                                {Icons.trash()}
                                            </div>
                                            <div className="mt-1px">
                                                <Trans>Remove</Trans>
                                            </div>
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default LocationsTable
