import axios from "axios"
import { AppRoutes } from "../utilities/AppRoutes"
import { getUserToken } from "../functions/getUserToken"

const BaseApiUrl = process.env.REACT_APP_API_BASE_URL
const ApiKey = process.env.REACT_APP_API_KEY

export const axiosRequest = async ({
    url,
    method,
    body = null,
    headers = null,
    isProtected = false,
}) => {
    let output = { error: null, data: null }
    try {
        const response = await axios({
            url: `${BaseApiUrl}${url}`,
            method: method,
            ...(body && {
                data: JSON.stringify(body),
            }),
            headers: {
                "Content-Type": "application/json",
                "Api-Key": ApiKey,
                ...(isProtected && {
                    Authorization: `Bearer ${getUserToken()}`,
                }),
                ...headers,
            },
        })
        if (response) {
            if ("error" in response.data)
                return (output = {
                    error: response.data.error,
                    data: null,
                })
            return (output = {
                error: null,
                data: response.data,
            })
        }
        return (output = {
            error: "Something went wrong!",
            data: null,
        })
    } catch (error) {
        if (error?.response?.statusText === "Unauthorized")
            return (window.location.pathname = AppRoutes.logout)

        return (output = {
            error: error.message,
            data: null,
        })
    }
}
