import React from "react"
import Pagination from "rc-pagination"
import { useFilters } from "../../../providers/FiltersProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import NoData from "../../template/NoData"
import { Trans } from "react-i18next"

const ArchivedFiltersTable = () => {
    const {
        perPage,
        loading,
        items,
        currentPage,
        setCurrentPage,
        totalItems,
        setSelectedItem,
        activateFilter,
        disableFilter,
    } = useFilters()

    return (
        <div className="w-100">
            <div className="w-100 bg-light">
                <table className="table align-middle overflow-hidden rounded-top">
                    <thead className="bg-white table-primary text-white">
                        <tr>
                            <th>
                                <Trans>Filter</Trans>
                            </th>
                            <th>
                                <Trans>Order</Trans>
                            </th>
                        </tr>
                    </thead>
                    {loading ? (
                        <tr style={{ height: `${perPage * 50}px` }}>
                            <td colSpan={2}>
                                <EllipsisLoader dark />
                            </td>
                        </tr>
                    ) : (
                        <tbody>
                            {(!items || items?.length === 0) && (
                                <tr className="text-center">
                                    <td className="fw-600" colSpan={2}>
                                        <div className="w-25 mx-auto">
                                            <NoData />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {items?.length > 0 &&
                                items.map((item, index) => {
                                    return (
                                        <tr key={index} className="bg-white">
                                            <td>{item.name}</td>
                                            <td>{item.order}</td>
                                        </tr>
                                    )
                                })}
                            {items?.length > 0 &&
                                items.length !== perPage &&
                                Array.from(
                                    Array(perPage - items.length).keys()
                                ).map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="invisible border-1 border-light bg-light h-50px"
                                        >
                                            <td className="fw-bold" colSpan={2}>
                                                Data
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    )}
                </table>
            </div>
            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default ArchivedFiltersTable
