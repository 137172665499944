import React from "react"
import { Modal } from "react-bootstrap"
import {
    useCampaigns,
    EditModalSteps as ModalSteps,
} from "../../../../providers/CampaignsProvider"
import ErrorBox from "../../../template/ErrorBox"
import useScreen from "../../../../api/useScreen"
import DetailsForm from "./details-form/DetailsForm"
import UsageLimitsForm from "./usage-limits-form/UsageLimitsForm"
import AssignmentForm from "./assignment-form/AssignmentForm"
import AssignmentFormProvider from "./assignment-form/AssignmentFormProvider"
import LocationsForm from "./locations-form/LocationsForm"
import LocationsFormProvider from "./locations-form/LocationsFormProvider"
import SummaryForm from "./summary-form/SummaryForm"
import SummaryFormProvider from "./summary-form/SummaryFormProvider"
import StepProgress from "./StepProgress"
import { Trans } from "react-i18next"

const EditModal = () => {
    // States and Hooks
    const { isMobile } = useScreen()
    const {
        isEditModalOpen: isOpen,
        editModalStep,
        error,
        closeEditModal: closeModal,
    } = useCampaigns()

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "xl"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Trans>Edit Campaign</Trans>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0">
                <StepProgress />
                <div className="px-3">
                    <ErrorBox error={error} />
                    {editModalStep === ModalSteps.Details && <DetailsForm />}

                    {editModalStep === ModalSteps.UsageLimits && (
                        <UsageLimitsForm />
                    )}

                    {editModalStep === ModalSteps.Assignments && (
                        <AssignmentFormProvider>
                            <AssignmentForm />
                        </AssignmentFormProvider>
                    )}

                    {editModalStep === ModalSteps.Locations && (
                        <LocationsFormProvider>
                            <LocationsForm />
                        </LocationsFormProvider>
                    )}

                    {editModalStep === ModalSteps.Summary && (
                        <SummaryFormProvider>
                            <SummaryForm />
                        </SummaryFormProvider>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditModal
