import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useUsers } from "../../../providers/UsersProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import Input from "../../template/Input"
import { Trans, useTranslation } from "react-i18next"

const ArchiveUserModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { user } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isArchiveModalOpen: isOpen,
        setIsArchiveModalOpen: setIsOpen,
        initialize,
        selectedItem,
        setSelectedItem,
    } = useUsers()

    const userArchiveHandler = async e => {
        e.preventDefault()
        setError("")
        setLoading(true)

        const { data, error } = await user.archive({
            token: selectedItem.token,
        })

        if (error) setError(error)

        if (data) {
            closeModal()
            await initialize()
            toast.success(t("User archived successfully"))
        }

        setLoading(false)
    }

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
        setSelectedItem(null)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "md"}
        >
            <form onSubmit={userArchiveHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Archive User</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="d-flex align-items-center gap-2 flex-wrap">
                        <Trans>Are you sure you want to archive</Trans>
                        <span>
                            <Input
                                type="text"
                                value={selectedItem.phone_number}
                                mask="(999) 999 99 99"
                                className="form-control border-0 text-center phone-number-container px-0"
                                disabled
                            />
                        </span>
                        ?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Archive</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ArchiveUserModal
