import React from "react"
import { Form } from "react-bootstrap"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import CampaignDetailsItem from "./CampaignDetailsItem"
import formatDateTimeValue from "../../../../../functions/formatDateTimeValue"
import CampaignOwnerItem from "./CampaignOwnerItem"
import FiltersItem from "./FiltersItem"
import SegmentsItem from "./SegmentsItem"
import LocationsItem from "./LocationsItem"
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"

const CampaignDetails = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { campaignValues, selectedItem } = useCampaigns()

    return (
        <div className="row m-0 mb-3">
            {campaignValues?.thumbnails?.length > 0 && (
                <CampaignDetailsItem title="Thumbnails">
                    <Swiper
                        className="w-100"
                        slidesPerView={"auto"}
                        spaceBetween={10}
                    >
                        {campaignValues.thumbnails.map((thumbnail, index) => (
                            <SwiperSlide className="thumbnail-view-container rounded">
                                <img
                                    src={thumbnail}
                                    className="w-100 h-auto rounded thumbnail-image"
                                    alt={`campaign-thumbnail-${index}`}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </CampaignDetailsItem>
            )}

            <CampaignDetailsItem title="Name" value={campaignValues?.name} />
            <CampaignDetailsItem
                title="Capacity"
                value={
                    selectedItem?.capacity
                        ? selectedItem?.capacity
                        : t("Visual")
                }
            />
            <CampaignDetailsItem
                title="Start"
                value={formatDateTimeValue(new Date(campaignValues?.startDate))}
            />
            <CampaignDetailsItem
                title="End"
                value={formatDateTimeValue(new Date(campaignValues?.endDate))}
            />
            <CampaignDetailsItem
                title="Usage limit count"
                value={
                    campaignValues?.usageLimitCount === -1
                        ? t("Unlimited")
                        : campaignValues?.usageLimitCount
                }
            />
            <CampaignDetailsItem
                title="Usage limit type"
                value={t(campaignValues?.usageLimitType)}
            />
            <CampaignOwnerItem />
            <CampaignDetailsItem title="Has feedback">
                <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    checked={campaignValues?.hasFeedback}
                    disabled
                />
            </CampaignDetailsItem>
            <CampaignDetailsItem title="Description">
                <div
                    dangerouslySetInnerHTML={{
                        __html: campaignValues?.description,
                    }}
                />
            </CampaignDetailsItem>
            <FiltersItem />
            <SegmentsItem />
            {campaignValues?.locations?.length > 0 && <LocationsItem />}
        </div>
    )
}

export default CampaignDetails
