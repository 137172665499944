import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Input from "../../../../template/Input"
import { getCodesFromFile } from "../../../../../functions/getCodesFromFile"
import { Icons } from "../../../../../utilities/Icons"
import { Trans } from "react-i18next"

const FormFields = ({ formik }) => {
    const [uniqueCodesCount, setUniqueCodesCount] = useState(0)
    const [duplicateCodesCount, setDuplicateCodesCount] = useState(0)

    const templateURL = process.env.REACT_APP_CODES_TEMPLATE_URL

    const openFileSelect = e => {
        e.preventDefault()
        document.getElementById("codesFile").click()
    }

    const codesFileHandler = async () => {
        const fileCodes = await getCodesFromFile({
            file: formik.values.codesFile,
            hasHeaders: formik.values.codesFileHasHeaders,
        })
        const totalCodesCount = fileCodes.length
        const totalUniqueCodesCount = [...new Set(fileCodes)].length
        setUniqueCodesCount(totalUniqueCodesCount)
        setDuplicateCodesCount(totalCodesCount - totalUniqueCodesCount)
    }

    useEffect(() => {
        if (formik.values?.codesFile) codesFileHandler()
    }, [formik.values?.codesFile, formik.values?.codesFileHasHeaders])

    return (
        <div className="row m-0 mb-3">
            <div className="col-3 d-flex align-items-center gap-2 p-0">
                <label className="fw-500 no-select" htmlFor="status-checkbox">
                    <Trans>Visual</Trans>:
                </label>
                <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    id="codeless"
                    name="codeless"
                    onChange={e => {
                        formik.setFieldValue("codes", [])
                        formik.handleChange(e)
                    }}
                    checked={formik.values.codeless}
                />
            </div>
            <div className="col-9 p-0">
                <div className="mb-3 col-12 col-sm-12 d-flex align-items-center gap-2 p-0">
                    <label
                        className="fw-500 no-select"
                        htmlFor="status-checkbox"
                    >
                        <Trans>Auto generate</Trans>:
                    </label>
                    <Form.Check
                        className="custom-checkbox"
                        type="checkbox"
                        id="autoGenerateCodes"
                        name="autoGenerateCodes"
                        onChange={e => {
                            formik.setFieldValue("codes", [])
                            formik.handleChange(e)
                        }}
                        checked={formik.values.autoGenerateCodes}
                        disabled={formik.values.codeless}
                    />
                </div>

                {formik.values.autoGenerateCodes && (
                    <div className="mb-3 col-12 col-sm-12 p-0">
                        <label className="fw-500">
                            <Trans>Count of codes</Trans>:
                        </label>
                        <Input
                            id="countOfCodes"
                            name="countOfCodes"
                            type="number"
                            disabled={
                                !formik.values.autoGenerateCodes ||
                                formik.values.codeless
                            }
                            onChange={formik.handleChange}
                            value={formik.values.countOfCodes}
                            className="form-control rounded-0"
                            placeholder="Enter count of codes"
                        />
                    </div>
                )}

                {!formik.values.autoGenerateCodes && (
                    <div className="col-12 col-sm-12 p-0">
                        <div className="mb-3 d-flex align-items-center gap-2">
                            <label className="fw-500">
                                <Trans>Codes file</Trans>:
                            </label>
                            <div className="fs-xs d-flex align-items-center gap-1">
                                <div>
                                    <Trans>
                                        Only .xls, .xlsx and .csv files are
                                        supported.
                                    </Trans>
                                </div>
                                <a
                                    href={`${templateURL}`}
                                    target="_blank"
                                    className="text-decoration-underline"
                                >
                                    <Trans>Template</Trans>
                                </a>
                            </div>
                        </div>
                        <div className="mb-3 d-flex align-items-center gap-2 w-75">
                            <button
                                className="btn btn-primary rounded-0 fw-600 text-nowrap"
                                disabled={
                                    formik.values.autoGenerateCodes ||
                                    formik.values.codeless
                                }
                                type="button"
                                onClick={openFileSelect}
                            >
                                <Trans>Choose file</Trans>
                            </button>

                            <div>{formik.values.codesFile?.name}</div>

                            <input
                                id="codesFile"
                                name="codesFile"
                                type="file"
                                onChange={e =>
                                    formik.setFieldValue(
                                        "codesFile",
                                        e.target.files[0]
                                    )
                                }
                                hidden
                            />
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <label
                                className="fw-500 no-select"
                                htmlFor="status-checkbox"
                            >
                                <Trans>Has headers</Trans>:
                            </label>
                            <Form.Check
                                className="custom-checkbox"
                                type="checkbox"
                                id="codesFileHasHeaders"
                                name="codesFileHasHeaders"
                                onChange={formik.handleChange}
                                checked={formik.values.codesFileHasHeaders}
                                disabled={formik.values.codeless}
                            />
                        </div>
                    </div>
                )}

                <div className="col-12 col-sm-12 p-0 d-flex align-items-center gap-1 overflow-hidden">
                    {!formik.values.autoGenerateCodes &&
                        formik.values.codesFile && (
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="crud-icon">
                                        {Icons.check()}
                                    </div>
                                    <div className="mt-1px">{`Total unique codes affecting: ${uniqueCodesCount}`}</div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="crud-icon">
                                        {Icons.close()}
                                    </div>
                                    <div className="mt-1px">{`Total duplicate codes found: ${duplicateCodesCount}`}</div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default FormFields
