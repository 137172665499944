import { axiosRequest } from "../axiosRequest"

export const UserController = {
    fetch: async ({ size, page }) =>
        await axiosRequest({
            url: "/user/fetch",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    create: async ({ phoneNumber, isActive, gender, birthDate = null }) =>
        await axiosRequest({
            url: "/user/create",
            method: "post",
            body: {
                phone_number: phoneNumber,
                is_active: isActive,
                gender,
                ...(birthDate && { birth_date: birthDate }),
            },
            isProtected: true,
        }),
    update: async ({ token, phoneNumber, gender, birthDate = null }) =>
        await axiosRequest({
            url: "/user/update",
            method: "post",
            body: {
                token,
                phone_number: phoneNumber,
                gender,
                birth_date: birthDate,
            },
            isProtected: true,
        }),
    activate: async ({ token }) =>
        await axiosRequest({
            url: "/user/activate",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    disable: async ({ token }) =>
        await axiosRequest({
            url: "/user/disable",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    archive: async ({ token }) =>
        await axiosRequest({
            url: "/user/archive",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    fetchArchived: async ({ size, page }) =>
        await axiosRequest({
            url: "/user/fetch-archived",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    addSegment: async ({ userToken, segmentToken }) =>
        await axiosRequest({
            url: "/user-segment/create",
            method: "post",
            body: { user_token: userToken, segment_token: segmentToken },
            isProtected: true,
        }),
    deleteSegment: async ({ userToken, segmentToken }) =>
        await axiosRequest({
            url: "/user-segment/delete",
            method: "post",
            body: { user_token: userToken, segment_token: segmentToken },
            isProtected: true,
        }),
}
