import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { useApi } from "./ApiProvider"

const AdminsContext = createContext()
export const useAdmins = () => useContext(AdminsContext)

const AdminsProvider = ({ children }) => {
    // States and Hooks
    const { admin } = useApi()
    const perPage = 7
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    // Methods
    const fetchAdmins = async () => {
        const response = await admin.fetch({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        setItems(response.data.rows)
    }

    const initialize = async () => {
        setLoading(true)
        await fetchAdmins()
        setLoading(false)
    }

    // UseEffect
    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        initialize()
    }, [currentPage])

    // Binding
    const valueObject = {
        // States
        perPage,

        loading,
        setLoading,

        items,
        setItems,

        currentPage,
        setCurrentPage,

        totalItems,
        setTotalItems,

        selectedItem,
        setSelectedItem,

        isEditModalOpen,
        setIsEditModalOpen,

        isCreateModalOpen,
        setIsCreateModalOpen,

        isDeleteModalOpen,
        setIsDeleteModalOpen,

        // Methods
        initialize,
    }

    // Render
    return (
        <AdminsContext.Provider value={valueObject}>
            {children}
        </AdminsContext.Provider>
    )
}

export default AdminsProvider
