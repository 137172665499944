import React from "react"
import { useFormik } from "formik"
import {
    useCampaigns,
    CampaignUsageLimitTypes,
} from "../../../../../providers/CampaignsProvider"
import { CampaignUsageLimitsFormSchema } from "../../../../../utilities/FormValidations"
import FormFields from "./FormFields"
import FormButtons from "./FormButtons"

const UsageLimitsForm = () => {
    // States and Hooks
    const {
        campaignValues,
        setCampaignValues,
        setError,
        createModalNextStepHandler: nextStepHandler,
    } = useCampaigns()

    const campaignUsageLimitsFormik = useFormik({
        initialValues: {
            usageLimitCount:
                campaignValues?.usageLimitCount === -1
                    ? undefined
                    : campaignValues?.usageLimitCount,
            unlimited: campaignValues?.unlimited ? true : false,
            usageLimitType: campaignValues?.usageLimitType
                ? campaignValues.usageLimitType
                : "",
        },
        onSubmit: async values => {
            setError("")

            if (values.unlimited) {
                setCampaignValues({
                    ...campaignValues,
                    usageLimitCount: -1,
                    usageLimitType: CampaignUsageLimitTypes.AllTime.label,
                    unlimited: true,
                })
                return nextStepHandler()
            }

            const validationResponse =
                await CampaignUsageLimitsFormSchema.validate({
                    usageLimitCount: values.usageLimitCount,
                    usageLimitType: values.usageLimitType,
                }).catch(error => setError(error.message))

            if (validationResponse) {
                setCampaignValues({
                    ...campaignValues,
                    usageLimitCount: values.usageLimitCount,
                    usageLimitType: values.usageLimitType,
                    unlimited: false,
                })
                return nextStepHandler()
            }
        },
    })

    return (
        <form onSubmit={campaignUsageLimitsFormik.handleSubmit}>
            <FormFields formik={campaignUsageLimitsFormik} />
            <FormButtons />
        </form>
    )
}

export default UsageLimitsForm
