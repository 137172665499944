import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { Trans } from "react-i18next"

const FiltersItem = () => {
    const { campaignValues } = useCampaigns()

    return (
        <div className="d-flex gap-2 mb-2 pb-2 col-12 px-0 border-bottom">
            <div className="fw-500">
                <Trans>Filters</Trans>:
            </div>
            <div className="d-flex gap-2">
                {campaignValues?.filters.map((filter, index) => (
                    <>
                        <div>{filter.name}</div>
                        {index < campaignValues.filters.length - 1 && "|"}
                    </>
                ))}
            </div>
        </div>
    )
}

export default FiltersItem
