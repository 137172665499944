import React from "react"
import { GoogleMap, MarkerF } from "@react-google-maps/api"
import { useCampaigns } from "../../../../providers/CampaignsProvider"
import { Trans } from "react-i18next"

const LocationsItem = () => {
    // States and Hooks
    const { mapCenterLocation: centerLocation, selectedItem } = useCampaigns()

    return (
        <div className="d-flex gap-2 pb-2 col-12 px-0">
            <div className="fw-500 mb-1">
                <Trans>Locations</Trans>:
            </div>
            <div className="w-100">
                <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "400px",
                    }}
                    zoom={6}
                    center={centerLocation}
                    options={{
                        zoomControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                    }}
                >
                    {JSON.parse(selectedItem?.locations).map(
                        ({ lat, lng, name }) => (
                            <MarkerF
                                key={name}
                                label={name}
                                position={{ lat, lng }}
                                options={{
                                    label: {
                                        text: name,
                                        className: "custom-map-marker",
                                    },
                                }}
                            />
                        )
                    )}
                </GoogleMap>
            </div>
        </div>
    )
}

export default LocationsItem
