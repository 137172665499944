import React from "react"
import Pagination from "rc-pagination"
import { useSegments } from "../../../providers/SegmentsProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import NoData from "../../template/NoData"
import ColorSegment from "../../template/ColorSegment"
import { Trans } from "react-i18next"

const ArchivedSegmentsTable = () => {
    const { perPage, loading, items, currentPage, setCurrentPage, totalItems } =
        useSegments()

    return (
        <div className="w-100">
            <div className="w-100 bg-light">
                <table className="table align-middle overflow-hidden rounded-top">
                    <thead className="bg-white table-primary text-white">
                        <tr>
                            <th>
                                <Trans>Title</Trans>
                            </th>
                            <th>
                                <Trans>Gender</Trans>
                            </th>
                            <th>
                                <Trans>Color</Trans>
                            </th>
                        </tr>
                    </thead>
                    {loading ? (
                        <tr style={{ height: `${perPage * 50}px` }}>
                            <td colSpan={3}>
                                <EllipsisLoader dark />
                            </td>
                        </tr>
                    ) : (
                        <tbody>
                            {(!items || items?.length === 0) && (
                                <tr className="text-center">
                                    <td className="fw-600" colSpan={3}>
                                        <div className="w-25 mx-auto">
                                            <NoData />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {items?.length > 0 &&
                                items.map((item, index) => {
                                    return (
                                        <tr key={index} className="bg-white">
                                            <td>{item.title}</td>
                                            <td>
                                                <Trans>{item.gender}</Trans>
                                            </td>
                                            <td>
                                                <ColorSegment
                                                    color={item.color}
                                                    text={item.color}
                                                    className="text-uppercase"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            {items?.length > 0 &&
                                items.length !== perPage &&
                                Array.from(
                                    Array(perPage - items.length).keys()
                                ).map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="invisible border-1 border-light bg-light h-50px"
                                        >
                                            <td className="fw-bold" colSpan={3}>
                                                Data
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    )}
                </table>
            </div>
            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default ArchivedSegmentsTable
