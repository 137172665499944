import React from "react"
import { useFormik } from "formik"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { CampaignCodesFormSchema } from "../../../../../utilities/FormValidations"
import FormButtons from "./FormButtons"
import FormFields from "./FormFields"
import { getCodesFromFile } from "../../../../../functions/getCodesFromFile"
import generateRandomCodes from "../../../../../functions/generateRandomCodes"

const CodesForm = () => {
    // States and Hooks
    const {
        campaignValues,
        setCampaignValues,
        setError,
        createModalNextStepHandler: nextStepHandler,
    } = useCampaigns()

    const campaignCodesFormik = useFormik({
        initialValues: {
            autoGenerateCodes: campaignValues?.autoGenerateCodes
                ? campaignValues.autoGenerateCodes
                : false,
            codeless: campaignValues?.codeless
                ? campaignValues.codeless
                : false,
            countOfCodes: campaignValues?.countOfCodes
                ? campaignValues.countOfCodes
                : undefined,
            codes: campaignValues?.codes ? campaignValues.codes : [],
            codesFile: campaignValues?.codesFile
                ? campaignValues.codesFile
                : null,
            codesFileHasHeaders: campaignValues?.codesFileHasHeaders
                ? campaignValues.codesFileHasHeaders
                : false,
        },
        onSubmit: async values => {
            if (values.codeless) {
                setCampaignValues({
                    ...campaignValues,
                    countOfCodes: 0,
                    autoGenerateCodes: false,
                    codesFile: null,
                    codesFileHasHeaders: false,
                    codes: [],
                    codeless: true,
                })
                return nextStepHandler()
            }

            let codes
            if (values.autoGenerateCodes) {
                codes = generateRandomCodes(values.countOfCodes)
            } else {
                const fileCodes = await getCodesFromFile({
                    file: values.codesFile,
                    hasHeaders: values.codesFileHasHeaders,
                })
                codes = [...new Set(fileCodes)]
            }

            setError("")
            const validationResponse = await CampaignCodesFormSchema.validate({
                countOfCodes: values.autoGenerateCodes
                    ? values.countOfCodes
                    : codes.length,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                setCampaignValues({
                    ...campaignValues,
                    countOfCodes: values.countOfCodes,
                    autoGenerateCodes: values.autoGenerateCodes,
                    codesFile: values.autoGenerateCodes
                        ? null
                        : values.codesFile,
                    codesFileHasHeaders: values.autoGenerateCodes
                        ? false
                        : values.codesFileHasHeaders,
                    codes,
                    codeless: false,
                })
                nextStepHandler()
            }
        },
    })

    return (
        <form onSubmit={campaignCodesFormik.handleSubmit}>
            <FormFields formik={campaignCodesFormik} />
            <FormButtons />
        </form>
    )
}

export default CodesForm
