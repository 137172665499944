import React from "react"
import Layout from "../components/template/Layout"
import AdminsTable from "../components/page-components/admins/AdminsTable"
import { useAdmins } from "../providers/AdminsProvider"
import EditAdminModal from "../components/page-components/admins/EditAdminModal"
import CreateAdminModal from "../components/page-components/admins/CreateAdminModal"
import DeleteAdminModal from "../components/page-components/admins/DeleteAdminModal"
import { Icons } from "../utilities/Icons"
import { Trans } from "react-i18next"

const Admins = () => {
    const {
        isEditModalOpen,
        isDeleteModalOpen,
        isCreateModalOpen,
        setIsCreateModalOpen,
    } = useAdmins()

    const openCreateModal = () => setIsCreateModalOpen(true)

    return (
        <Layout>
            <div className="p-4">
                <button
                    className="btn btn-primary mb-3 d-flex align-items-center"
                    onClick={openCreateModal}
                >
                    <div className="small-icon">{Icons.add()}</div>
                    <div>
                        <Trans>Add Admin</Trans>
                    </div>
                </button>
                {isCreateModalOpen && <CreateAdminModal />}
                {isEditModalOpen && <EditAdminModal />}
                {isDeleteModalOpen && <DeleteAdminModal />}
                <AdminsTable />
            </div>
        </Layout>
    )
}

export default Admins
