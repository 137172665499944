import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import formatDateTimeValue from "../../../../../functions/formatDateTimeValue"
import ColorSegment from "../../../../template/ColorSegment"

const TableBody = () => {
    // States and Hooks
    const { perPage, items } = useCampaigns()

    return (
        <>
            {items?.length > 0 &&
                items.map((item, index) => {
                    return (
                        <tr key={index} className="bg-white">
                            <td>
                                <div className="d-flex gap-2 align-items-center">
                                    <img
                                        src={item?.campaign_owner?.logo}
                                        alt="campaignOwnerLogo"
                                        className="large-icon"
                                    />
                                    <div>
                                        {item?.campaign_owner?.organization}
                                    </div>
                                </div>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.capacity}</td>
                            <td>
                                {formatDateTimeValue(
                                    new Date(item?.start_date)
                                )}
                            </td>
                            <td>
                                {formatDateTimeValue(new Date(item?.end_date))}
                            </td>
                            <td>
                                <ColorSegment
                                    text={item.status.name}
                                    color={item.status.color}
                                />
                            </td>
                            <td>
                                <div className="d-flex justify-content-end gap-1"></div>
                            </td>
                        </tr>
                    )
                })}
            {items?.length > 0 &&
                items.length !== perPage &&
                Array.from(Array(perPage - items.length).keys()).map(
                    (item, index) => {
                        return (
                            <tr
                                key={index}
                                className="invisible border-1 border-light bg-light h-50px"
                            >
                                <td className="fw-bold" colSpan={6}>
                                    Data
                                </td>
                            </tr>
                        )
                    }
                )}
        </>
    )
}

export default TableBody
