import { axiosRequest } from "../axiosRequest"

export const DashboardController = {
    fetchCounts: async () =>
        await axiosRequest({
            url: "/dashboard/fetch-counts",
            method: "post",
            isProtected: true,
        }),
}
