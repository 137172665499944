import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useCampaignOwners } from "../../../providers/CampaignOwnersProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import { CreateCampaignOwnerFormSchema } from "../../../utilities/FormValidations"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import { getBase64String } from "../../../functions/getBase64String"
import { Trans, useTranslation } from "react-i18next"

const CreateCampaignOwnerModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { campaignOwner } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isCreateModalOpen: isOpen,
        setIsCreateModalOpen: setIsOpen,
        initialize,
    } = useCampaignOwners()

    const createCampaignOwnerFormik = useFormik({
        initialValues: {
            organization: "",
            logo: "",
            isActive: false,
        },
        onSubmit: async values => {
            setError("")
            setLoading(true)

            const validationResponse =
                await CreateCampaignOwnerFormSchema.validate({
                    organization: values.organization,
                    logo: values.logo,
                }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await campaignOwner.create({
                    organization: values.organization,
                    logo: values.logo,
                    isActive: values.isActive,
                })
                if (error) setError(error)
                if (data) {
                    closeModal()
                    await initialize()
                    toast.success(t("Campaign owner created successfully"))
                }
            }
            setLoading(false)
        },
    })

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    const openFileSelect = e => {
        e.preventDefault()
        document.getElementById("logo").click()
    }

    const selectLogoHandler = async e => {
        if (e.target.files[0].size > 2 * 1024 * 1024)
            return setError("Logo is too large. Maximum allowable size is 2 MB")
        const base64Logo = await getBase64String(e.target.files[0])
        createCampaignOwnerFormik.setFieldValue("logo", base64Logo)
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={createCampaignOwnerFormik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Create Campaign Owner</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Organization</Trans>:
                        </label>
                        <input
                            id="organization"
                            name="organization"
                            type="text"
                            disabled={loading}
                            onChange={createCampaignOwnerFormik.handleChange}
                            value={
                                createCampaignOwnerFormik.values.organization
                            }
                            className="form-control rounded-0 mt-1"
                            placeholder={t("Organization")}
                        />
                    </div>
                    <div className="mb-3 d-flex align-items-center gap-2">
                        <label className="fw-500">
                            <Trans>Logo</Trans>:
                        </label>
                        <div className="d-flex align-items-center gap-2 w-75">
                            <button
                                className="btn btn-primary rounded-0 fw-600 text-nowrap"
                                type="button"
                                onClick={openFileSelect}
                                disabled={loading}
                            >
                                <Trans>Choose file</Trans>
                            </button>

                            {createCampaignOwnerFormik.values.logo && (
                                <div className="d-flex align-items-center gap-1">
                                    <div className="fw-500">
                                        <Trans>Preview</Trans>:
                                    </div>
                                    <img
                                        src={
                                            createCampaignOwnerFormik.values
                                                .logo
                                        }
                                        alt="logo"
                                        className="campaign-logo"
                                    />
                                </div>
                            )}

                            <input
                                id="logo"
                                name="logo"
                                type="file"
                                disabled={loading}
                                onChange={selectLogoHandler}
                                hidden
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <label
                            className="fw-500 no-select"
                            htmlFor="status-checkbox"
                        >
                            <Trans>Is Active</Trans>:
                        </label>
                        <Form.Check
                            className="custom-checkbox"
                            type="checkbox"
                            id="isActive"
                            name="isActive"
                            disabled={loading}
                            onChange={createCampaignOwnerFormik.handleChange}
                            value={createCampaignOwnerFormik.values.isActive}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            type="button"
                            onClick={closeModal}
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Add</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateCampaignOwnerModal
