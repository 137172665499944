import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"

const CampaignOwnerItem = () => {
    const { campaignValues } = useCampaigns()

    return (
        <div className="d-flex gap-2 mb-2 pb-2 col-12 px-0 border-bottom align-items-center">
            <div className="fw-500">Campaign owner:</div>
            <div>
                <img
                    src={campaignValues?.campaignOwner?.logo}
                    alt="campaignOwnerLogo"
                    className="large-icon"
                />
            </div>
            <div>{campaignValues?.campaignOwner?.organization}</div>
        </div>
    )
}

export default CampaignOwnerItem
