import { useNavigate } from "react-router"
import { AppRoutes } from "../utilities/AppRoutes"
import { storeUserToken } from "../functions/storeUserToken"
import { createContext, useContext } from "react"
import { useState } from "react"
import { LoginFormSchema } from "../utilities/FormValidations"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import { useApi } from "./ApiProvider"
import { useApp } from "./AppProvider"
import { useTranslation } from "react-i18next"

const LoginContext = createContext()
export const useLogin = () => useContext(LoginContext)

const LoginProvider = ({ children }) => {
    // States and Hooks
    const { t } = useTranslation()
    const { fetchCurrentAdmin } = useApp()
    const { admin } = useApi()
    const navigator = useNavigate()
    const [error, setError] = useState("")
    const [loadingLoginButton, setLoadingLoginButton] = useState(false)

    const loginFormik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        onSubmit: async values => {
            setError("")
            setLoadingLoginButton(true)

            const validationResponse = await LoginFormSchema.validate({
                username: values.username,
                password: values.password,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                setError("")
                const { data, error } = await admin.login({
                    email: values.username,
                    password: values.password,
                })

                if (error) toast.error(t("Email or password is incorrect"))

                if (data) {
                    storeUserToken({
                        userToken: data.accessToken,
                    })
                    await fetchCurrentAdmin()
                    toast.success(t("Logged in successfully"))
                    navigator(AppRoutes.home)
                }
            }
            setLoadingLoginButton(false)
        },
    })

    // Methods
    const onInputFocus = e => {
        e.target.previousSibling.classList.add("text-primary")
    }

    const onInputBlur = e => {
        if (!e.target.value)
            e.target.previousSibling.classList.remove("text-primary")
    }

    // Binding
    const valueObject = {
        // States
        loadingLoginButton,
        error,
        loginFormik,

        // Methods
        onInputFocus,
        onInputBlur,
    }

    // Render
    return (
        <LoginContext.Provider value={valueObject}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginProvider
