import { useState, useEffect } from "react"

const useScreen = () => {
    // States and Hooks
    const breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
    }
    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)
    const [isLargeDesktop, setIsLargeDesktop] = useState(false)
    const [isExtraLargeDesktop, setIsExtraLargeDesktop] = useState(false)

    // Methods
    const screenCleanUp = () => {
        setIsMobile(false)
        setIsTablet(false)
        setIsDesktop(false)
        setIsLargeDesktop(false)
        setIsExtraLargeDesktop(false)
    }

    const handler = () => {
        if (window.innerWidth < breakpoints.sm) {
            screenCleanUp()
            return setIsMobile(true)
        }

        if (window.innerWidth < breakpoints.md) {
            screenCleanUp()
            return setIsTablet(true)
        }

        if (window.innerWidth < breakpoints.lg) {
            screenCleanUp()
            return setIsDesktop(true)
        }

        if (window.innerWidth < breakpoints.xl) {
            screenCleanUp()
            return setIsLargeDesktop(true)
        }

        if (window.innerWidth < breakpoints.xxl) {
            screenCleanUp()
            return setIsExtraLargeDesktop(true)
        }
    }

    // UseEffects
    useEffect(() => {
        handler()
        window.addEventListener("resize", handler)
        return () => window.removeEventListener("resize", handler)
    }, [])

    return {
        isMobile,
        isTablet,
        isDesktop,
        isLargeDesktop,
        isExtraLargeDesktop,
    }
}

export default useScreen
