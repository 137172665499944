import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { TinySpinner } from "./Spinner"
import { Trans } from "react-i18next"

const ItemStatusSwitch = ({ item, activateHandler, disableHandler }) => {
    const [loading, setLoading] = useState(false)

    const onToggleStatusClick = async () => {
        setLoading(true)
        item.is_active
            ? await disableHandler(item.token)
            : await activateHandler(item.token)
        setLoading(false)
    }

    return (
        <div className="d-flex align-items-center">
            <div>
                <Trans>Inactive</Trans>
            </div>
            <Form className="custom-switch text-center">
                {loading ? (
                    <TinySpinner />
                ) : (
                    <Form.Check
                        type="switch"
                        disabled={loading}
                        onClick={onToggleStatusClick}
                        defaultChecked={item.is_active}
                    />
                )}
            </Form>
            <div>
                <Trans>Active</Trans>
            </div>
        </div>
    )
}

export default ItemStatusSwitch
