import { Navigate, Route, Routes } from "react-router"
import { BrowserRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify"
import { AppRoutes } from "./utilities/AppRoutes"
import { ProtectedRoute } from "./components/template/ProtectedRoute"
import AppProvider from "./providers/AppProvider"
import LoginProvider from "./providers/LoginProvider"
import ApiProvider from "./providers/ApiProvider"
import Login from "./pages/Login"
import Home from "./pages/Home"
import LogoutActions from "./pages/LogoutActions"
import Logout from "./pages/Logout"
import Filters from "./pages/Filters"
import FiltersProvider from "./providers/FiltersProvider"
import CampaignOwners from "./pages/CampaignOwners"
import CampaignOwnersProvider from "./providers/CampaignOwnersProvider"
import Admins from "./pages/Admins"
import AdminsProvider from "./providers/AdminsProvider"
import Segments from "./pages/Segments"
import SegmentsProvider from "./providers/SegmentsProvider"
import Users from "./pages/Users"
import UsersProvider from "./providers/UsersProvider"
import Campaigns from "./pages/Campaigns"
import CampaignsProvider from "./providers/CampaignsProvider"
import DashboardProvider from "./providers/DashboardProvider"

function App() {
    return (
        <ApiProvider>
            <ToastContainer autoClose={2000} pauseOnFocusLoss={false} />
            <BrowserRouter>
                <AppProvider>
                    <Routes>
                        {/* Unauthenticated Routes */}
                        <Route
                            path={AppRoutes.login}
                            element={
                                <LoginProvider>
                                    <Login />
                                </LoginProvider>
                            }
                        />

                        {/* Authenticated Routes */}
                        <Route
                            path={AppRoutes.home}
                            element={
                                <ProtectedRoute>
                                    <DashboardProvider>
                                        <Home />
                                    </DashboardProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.filters}
                            element={
                                <ProtectedRoute>
                                    <FiltersProvider>
                                        <Filters />
                                    </FiltersProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.campaignOwners}
                            element={
                                <ProtectedRoute>
                                    <CampaignOwnersProvider>
                                        <CampaignOwners />
                                    </CampaignOwnersProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.admins}
                            element={
                                <ProtectedRoute>
                                    <AdminsProvider>
                                        <Admins />
                                    </AdminsProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.segments}
                            element={
                                <ProtectedRoute>
                                    <SegmentsProvider>
                                        <Segments />
                                    </SegmentsProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.users}
                            element={
                                <ProtectedRoute>
                                    <UsersProvider>
                                        <Users />
                                    </UsersProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.campaigns}
                            element={
                                <ProtectedRoute>
                                    <CampaignsProvider>
                                        <Campaigns />
                                    </CampaignsProvider>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.logoutActions}
                            element={
                                <ProtectedRoute>
                                    <LogoutActions />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={AppRoutes.logout}
                            element={
                                <ProtectedRoute>
                                    <Logout />
                                </ProtectedRoute>
                            }
                        />

                        {/* 404 Route */}
                        <Route
                            path="*"
                            element={<Navigate replace to={AppRoutes.login} />}
                        />
                    </Routes>
                </AppProvider>
            </BrowserRouter>
        </ApiProvider>
    )
}

export default withTranslation()(App)
