import React from "react"
import styles from "../../styles/modules/components/EllipsisLoader.module.scss"

const EllipsisLoader = ({ dark }) => {
    // States and Hooks

    // Methods

    // Render
    return (
        <div className="w-100 d-flex align-items-center justify-content-center bg-transparent">
            <div
                className={`${styles["ellipsis-loader"]} ${
                    dark ? styles["loading-dark"] : styles["loading-light"]
                }`}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span className="invisible">Loading</span>
            </div>
        </div>
    )
}

export default EllipsisLoader
