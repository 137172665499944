import React, { useEffect } from "react"
import { useFormik } from "formik"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { CampaignDetailsFormSchema } from "../../../../../utilities/FormValidations"
import FormFields from "./FormFields"
import FormButtons from "./FormButtons"

const DetailsForm = () => {
    // States and Hooks
    const {
        campaignValues,
        setCampaignValues,
        setError,
        campaignOwnerItems,
        editModalNextStepHandler: nextStepHandler,
        selectedItem,
    } = useCampaigns()

    const campaignDetailsFormik = useFormik({
        initialValues: {
            name: campaignValues?.name ? campaignValues.name : "",
            startDate: campaignValues?.startDate
                ? campaignValues.startDate
                : undefined,
            endDate: campaignValues?.endDate
                ? campaignValues.endDate
                : undefined,
            campaignOwnerToken: campaignValues?.campaignOwner
                ? campaignValues.campaignOwner.token
                : "",
            hasFeedback: campaignValues?.hasFeedback
                ? campaignValues.hasFeedback
                : false,
            description: campaignValues?.description
                ? campaignValues.description
                : "",
            thumbnails: campaignValues?.thumbnails
                ? campaignValues.thumbnails
                : [],
        },
        enableReinitialize: true,
        onSubmit: async values => {
            setError("")
            const validationResponse = await CampaignDetailsFormSchema.validate(
                {
                    name: values.name,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    campaignOwnerToken: values.campaignOwnerToken,
                    description: values.description,
                    thumbnails: values.thumbnails,
                }
            ).catch(error => setError(error.message))

            if (validationResponse) {
                const selectedCampaignOwner = campaignOwnerItems.filter(
                    campaignOwner =>
                        campaignOwner.token === values.campaignOwnerToken
                )[0]

                setCampaignValues({
                    ...campaignValues,
                    name: values.name,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    campaignOwner: selectedCampaignOwner,
                    hasFeedback: values.hasFeedback,
                    description: values.description,
                    thumbnails: values.thumbnails,
                })
                nextStepHandler()
            }
        },
    })

    // UseEffects
    useEffect(() => {
        if (!campaignValues?.detailsFormInitialization)
            setCampaignValues({
                name: selectedItem.name,
                startDate: new Date(selectedItem.start_date),
                endDate: new Date(selectedItem.end_date),
                campaignOwner: { ...selectedItem.campaign_owner },
                hasFeedback: selectedItem.has_feedback,
                description: selectedItem.description,
                thumbnails: JSON.parse(selectedItem.thumbnails),
                detailsFormInitialization: true,
            })
    }, [])

    return (
        <form onSubmit={campaignDetailsFormik.handleSubmit}>
            <FormFields formik={campaignDetailsFormik} />
            <FormButtons />
        </form>
    )
}

export default DetailsForm
