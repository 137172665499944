const generateRandomCodes = count => {
    const tokens = []

    for (let i = 0; i < 1_000_000; i++) {
        const code = i.toString().padStart(6, "0")
        tokens.push(code)
    }

    for (let i = tokens.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[tokens[i], tokens[j]] = [tokens[j], tokens[i]]
    }

    return tokens.slice(0, count)
}

module.exports = generateRandomCodes
