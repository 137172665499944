import React, { createContext, useContext, useState } from "react"
import { toast } from "react-toastify"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { useApi } from "../../../../../providers/ApiProvider"
import { useTranslation } from "react-i18next"

const SummaryFormContext = createContext()
export const useSummaryForm = () => useContext(SummaryFormContext)

const SummaryFormProvider = ({ children }) => {
    // States and Hooks
    const { t } = useTranslation()

    const {
        initialize,
        campaignValues,
        setError,
        selectedItem,
        closeEditModal: closeModal,
    } = useCampaigns()

    const { campaign } = useApi()

    const [loading, setLoading] = useState(false)

    // Methods
    const submitHandler = async e => {
        e.preventDefault()
        setError("")
        setLoading(true)

        const { data, error } = await campaign.update({
            token: selectedItem.token,
            ...campaignValues,
            campaignOwnerToken: campaignValues.campaignOwner.token,
            filterTokens: campaignValues.filters.map(filter => filter.token),
            segmentTokens: campaignValues.segments.map(
                segment => segment.token
            ),
            locations:
                campaignValues.locations?.length > 0
                    ? campaignValues.locations
                    : null,
            thumbnails:
                campaignValues.thumbnails?.length > 0
                    ? campaignValues.thumbnails
                    : null,
        })

        if (data) {
            closeModal()
            await initialize()
            toast.success(t("Campaign edited successfully"))
        }

        if (error) setError(error)

        setLoading(false)
    }

    // Binding
    const valueObject = {
        // States
        loading,
        setLoading,

        // Methods
        submitHandler,
    }

    // Render
    return (
        <SummaryFormContext.Provider value={valueObject}>
            {children}
        </SummaryFormContext.Provider>
    )
}

export default SummaryFormProvider
