import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { useCampaigns } from "../../../providers/CampaignsProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import useScreen from "../../../api/useScreen"
import { Trans, useTranslation } from "react-i18next"

const CancelModal = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { campaign } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isCancelModalOpen: isOpen,
        setIsCancelModalOpen: setIsOpen,
        initialize,
        selectedItem,
        setSelectedItem,
    } = useCampaigns()

    const campaignCancelHandler = async e => {
        e.preventDefault()
        setError("")
        setLoading(true)

        const { data, error } = await campaign.cancel({
            token: selectedItem.token,
        })

        if (error) setError(error)

        if (data) {
            closeModal()
            await initialize()
            toast.success(t("Campaign canceled successfully"))
        }

        setLoading(false)
    }

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
        setSelectedItem(null)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "md"}
        >
            <form onSubmit={campaignCancelHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Cancel Campaign</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="d-flex align-items-center gap-2 flex-wrap">
                        <Trans>Are you sure you want to cancel</Trans>
                        <span className="fw-bold">{selectedItem.name}</span>?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                        >
                            <Trans>Close</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Cancel</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CancelModal
