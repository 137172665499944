import React from "react"
import styles from "../../styles/modules/components/Header.module.scss"
import LangSwitch from "./LangSwitch"
import { useApp } from "../../providers/AppProvider"
import { UnprotectedRoutes } from "../../utilities/AppRoutes"
import { Trans } from "react-i18next"

const Header = () => {
    // States and Hooks
    const { currentAdmin } = useApp()
    const { pathname } = window.location
    const showAdminInformation =
        !UnprotectedRoutes.includes(pathname) && currentAdmin

    // Render
    return (
        <div className={styles.header}>
            <LangSwitch />
            {showAdminInformation && (
                <div className="d-flex ms-auto align-items-center gap-2">
                    <div>
                        <Trans>Admin</Trans>:
                    </div>
                    <div className="fw-bold">{currentAdmin.email}</div>
                </div>
            )}
        </div>
    )
}

export default Header
