import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useUsers } from "../../../providers/UsersProvider"
import { useApi } from "../../../providers/ApiProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import ErrorBox from "../../template/ErrorBox"
import { CreateUserFormSchema } from "../../../utilities/FormValidations"
import useScreen from "../../../api/useScreen"
import { toast } from "react-toastify"
import { Form } from "react-bootstrap"
import { useFormik } from "formik"
import Input from "../../template/Input"
import { UsersGenderTypes } from "../../../providers/UsersProvider"
import DatePicker from "react-datepicker"
import { Trans, useTranslation } from "react-i18next"
import { tr, enGB } from "date-fns/locale"

const CreateUserModal = () => {
    // States and Hooks
    const { t, i18n } = useTranslation()
    const { user } = useApi()
    const { isMobile } = useScreen()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const {
        isCreateModalOpen: isOpen,
        setIsCreateModalOpen: setIsOpen,
        initialize,
    } = useUsers()

    let locale = enGB
    if (i18n.language === "tr") locale = tr

    const createUserFormik = useFormik({
        initialValues: {
            phoneNumber: "",
            gender: "",
            birthDate: null,
            isActive: false,
        },
        onSubmit: async values => {
            setError("")
            setLoading(true)
            const validationResponse = await CreateUserFormSchema.validate({
                phoneNumber: values.phoneNumber.replace(/[^\d]/g, ""),
                gender: values.gender,
                birthDate: values.birthDate,
            }).catch(error => setError(error.message))

            if (validationResponse) {
                const { data, error } = await user.create({
                    phoneNumber: values.phoneNumber.replace(/[^\d]/g, ""),
                    gender: values.gender,
                    birthDate: values.birthDate,
                    isActive: values.isActive,
                })
                if (error) setError(error)
                if (data) {
                    closeModal()
                    await initialize()
                    toast.success(t("User created successfully"))
                }
            }
            setLoading(false)
        },
    })

    // Methods
    const formCleanup = () => {
        setError("")
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false)
        formCleanup()
    }

    // Render
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <form onSubmit={createUserFormik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Create User</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorBox error={error} />
                    <div className="mb-3">
                        <label className="fw-500">
                            <Trans>Phone Number</Trans>:
                        </label>
                        <Input
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            disabled={loading}
                            onChange={createUserFormik.handleChange}
                            value={createUserFormik.values.phoneNumber}
                            className="form-control rounded-0"
                            placeholder="Phone Number"
                            mask="(999) 999 99 99"
                        />
                    </div>

                    <div className="mb-3 d-flex flex-column flex-lg-row align-items-center gap-lg-2">
                        <div className="w-100 mb-3 mb-lg-0">
                            <label className="fw-500">
                                <Trans>Gender</Trans>:
                            </label>
                            <Form.Select
                                id="gender"
                                name="gender"
                                disabled={loading}
                                onChange={createUserFormik.handleChange}
                                value={createUserFormik.values.gender}
                                className="form-control rounded-0"
                            >
                                <option value="">
                                    <Trans>Select Gender</Trans>
                                </option>
                                {UsersGenderTypes.map(gender => {
                                    return (
                                        <option value={gender}>
                                            <Trans>{gender}</Trans>
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                        <div className="w-100">
                            <label className="fw-500">
                                <Trans>Birth Date</Trans>:
                            </label>
                            <div>
                                <DatePicker
                                    id="birthDate"
                                    name="birthDate"
                                    disabled={loading}
                                    onChange={date => {
                                        createUserFormik.setFieldValue(
                                            "birthDate",
                                            date
                                        )
                                    }}
                                    selected={createUserFormik.values.birthDate}
                                    withPortal={isMobile}
                                    maxDate={new Date()}
                                    className="form-control rounded-0"
                                    wrapperClassName="w-100"
                                    dropdownMode="select"
                                    placeholderText={t("Select Birth Date")}
                                    dateFormat="dd/MM/yyyy"
                                    shouldCloseOnSelect
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    locale={locale}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center gap-2">
                        <label
                            className="fw-500 no-select"
                            htmlFor="status-checkbox"
                        >
                            <Trans>Is Active</Trans>:
                        </label>
                        <Form.Check
                            className="custom-checkbox"
                            type="checkbox"
                            id="isActive"
                            name="isActive"
                            disabled={loading}
                            onChange={createUserFormik.handleChange}
                            value={createUserFormik.values.isActive}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-3 col-12">
                        <button
                            className="btn btn-outline-dark fw-600 rounded-0 w-100"
                            onClick={closeModal}
                            type="button"
                        >
                            <Trans>Cancel</Trans>
                        </button>
                    </div>

                    <div className="col-lg-3 col-12">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary fw-600 rounded-0 w-100"
                        >
                            {loading ? (
                                <EllipsisLoader dark />
                            ) : (
                                <Trans>Add</Trans>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default CreateUserModal
