import React from "react"

const Button = ({
    onClick = () => {},
    disabled = false,
    variant = "primary",
    size = "",
    children,
    className = "",
    type = "button",
    ...rest
}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`btn ${
                size && `btn-${size}`
            } text-uppercase btn-${variant} fw-600 ${className}`}
        >
            {children}
        </button>
    )
}

export default Button
