import React from "react"
import Pagination from "rc-pagination"
import EllipsisLoader from "../../template/EllipsisLoader"
import NoData from "../../template/NoData"
import { useCampaignOwners } from "../../../providers/CampaignOwnersProvider"
import { Trans } from "react-i18next"

const ArchivedCampaignOwnersTable = () => {
    const { perPage, loading, items, currentPage, setCurrentPage, totalItems } =
        useCampaignOwners()

    return (
        <div className="w-100">
            <div className="w-100 bg-light">
                <table className="table align-middle overflow-hidden rounded-top">
                    <thead className="bg-white table-primary text-white">
                        <tr>
                            <th>
                                <Trans>Organization</Trans>
                            </th>
                        </tr>
                    </thead>
                    {loading ? (
                        <tbody>
                            <tr style={{ height: `${perPage * 50}px` }}>
                                <td colSpan={1}>
                                    <EllipsisLoader dark />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {(!items || items?.length === 0) && (
                                <tr className="text-center">
                                    <td className="fw-600" colSpan={1}>
                                        <div className="w-25 mx-auto">
                                            <NoData />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {items?.length > 0 &&
                                items.map((item, index) => {
                                    return (
                                        <tr key={index} className="bg-white">
                                            <td>{item.organization}</td>
                                        </tr>
                                    )
                                })}
                            {items?.length > 0 &&
                                items.length !== perPage &&
                                Array.from(
                                    Array(perPage - items.length).keys()
                                ).map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="invisible border-1 border-light bg-light h-50px"
                                        >
                                            <td className="fw-bold" colSpan={1}>
                                                Data
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    )}
                </table>
            </div>
            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default ArchivedCampaignOwnersTable
