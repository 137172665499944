import React from "react"
import {
    useCampaigns,
    EditModalSteps as ModalSteps,
} from "../../../../providers/CampaignsProvider"
import useScreen from "../../../../api/useScreen"
import { Trans } from "react-i18next"

const StepProgress = () => {
    const { isMobile, isTablet, isDesktop } = useScreen()
    const smallScreen = isMobile || isTablet || isDesktop

    return (
        <div className="w-100 d-flex align-items-center justify-content-between gap-2 border-bottom pb-4 mb-3 px-3">
            <Step
                title={smallScreen ? "1" : "Details"}
                modalStep={ModalSteps.Details}
            />
            <Step
                title={smallScreen ? "2" : "Usage Limits"}
                modalStep={ModalSteps.UsageLimits}
            />
            <Step
                title={smallScreen ? "3" : "Assignments"}
                modalStep={ModalSteps.Assignments}
            />
            <Step
                title={smallScreen ? "4" : "Locations"}
                modalStep={ModalSteps.Locations}
            />
            <Step
                title={smallScreen ? "5" : "Summary"}
                modalStep={ModalSteps.Summary}
            />
        </div>
    )
}

export default StepProgress

const Step = ({ title, modalStep }) => {
    const { editModalStep, setEditModalStep } = useCampaigns()
    return (
        <>
            {editModalStep === modalStep && (
                <button
                    className="w-100 d-flex flex-column gap-2 align-items-center bg-white border-0"
                    disabled
                >
                    <div className="text-success fw-600">
                        <Trans>{title}</Trans>
                    </div>
                    <div className="w-100 bg-success py-1 rounded"></div>
                </button>
            )}

            {editModalStep > modalStep && (
                <button
                    className="w-100 d-flex flex-column gap-2 align-items-center bg-white border-0"
                    onClick={() => setEditModalStep(modalStep)}
                >
                    <div className="fw-500">
                        <Trans>{title}</Trans>
                    </div>
                    <div className="w-100 bg-success py-1 rounded"></div>
                </button>
            )}

            {editModalStep < modalStep && (
                <button
                    className="w-100 d-flex flex-column gap-2 align-items-center bg-white border-0"
                    disabled
                >
                    <div className="text-lightgray fw-500">
                        <Trans>{title}</Trans>
                    </div>
                    <div className="w-100 bg-lightgray py-1 rounded"></div>
                </button>
            )}
        </>
    )
}
