import React from "react"
import { useCampaigns } from "../../../../../providers/CampaignsProvider"
import { useSummaryForm } from "./SummaryFormProvider"
import EllipsisLoader from "../../../../template/EllipsisLoader"
import { Trans } from "react-i18next"

const FormButtons = () => {
    // States and Hooks
    const { editModalBackStepHandler: backStepHandler } = useCampaigns()
    const { submitHandler, loading } = useSummaryForm()

    return (
        <div className="row m-0 justify-content-end">
            <div className="col-12 col-lg-3 p-0 mb-2 mb-lg-0 ps-lg-1 pe-lg-1">
                <button
                    className="btn btn-outline-dark fw-600 rounded-0 w-100"
                    onClick={backStepHandler}
                    type="button"
                >
                    <Trans>Back</Trans>
                </button>
            </div>

            <div className="col-12 col-lg-3 p-0 ps-lg-1 pe-lg-0">
                <button
                    type="submit"
                    onClick={submitHandler}
                    className="btn btn-primary fw-600 rounded-0 w-100"
                >
                    {loading ? <EllipsisLoader /> : <Trans>Update</Trans>}
                </button>
            </div>
        </div>
    )
}

export default FormButtons
