import React from "react"
import { Trans } from "react-i18next"

const TableHead = () => {
    return (
        <thead className="bg-white table-primary text-white">
            <tr>
                <th>
                    <Trans>Campaign owner</Trans>
                </th>
                <th>
                    <Trans>Name</Trans>
                </th>
                <th>
                    <Trans>Capacity</Trans>
                </th>
                <th>
                    <Trans>Start date</Trans>
                </th>
                <th>
                    <Trans>End date</Trans>
                </th>
                <th>
                    <Trans>Status</Trans>
                </th>
            </tr>
        </thead>
    )
}

export default TableHead
