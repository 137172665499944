import React, { createContext, useContext } from "react"

const HeaderContext = createContext()
export const useHeader = () => useContext(HeaderContext)

const HeaderProvider = ({ children }) => {
    // States & Hooks

    // Methods

    // UseEffects

    // Binding
    const valueObject = {
        // States
        // Methods
    }

    // Render
    return (
        <HeaderContext.Provider value={valueObject}>
            {children}
        </HeaderContext.Provider>
    )
}

export default HeaderProvider
