import React from "react"
import { useLocationsForm } from "./LocationsFormProvider"
import Input from "../../../../template/Input"
import { Trans } from "react-i18next"

const CreateLocationForm = () => {
    // States and Hooks
    const { locationCoordinates, campaignLocationFormik } = useLocationsForm()

    // Render
    return (
        <form onSubmit={campaignLocationFormik.handleSubmit} className="px-0">
            <div className="row m-0">
                <div className="mb-3 col-12 col-lg-5 ps-lg-0 pe-lg-1">
                    <label className="fw-500">
                        <Trans>Name</Trans>:
                    </label>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        onChange={campaignLocationFormik.handleChange}
                        value={campaignLocationFormik.values.name}
                        className="form-control rounded-0"
                        placeholder="Enter location name"
                    />
                </div>
                <div className="mb-3 col-12 col-sm-6 pe-sm-1 col-lg-2 ps-lg-1 pe-lg-1">
                    <label className="fw-500">
                        <Trans>Latitude</Trans>:
                    </label>
                    <Input
                        type="text"
                        disabled={true}
                        value={
                            locationCoordinates?.lat
                                ? locationCoordinates?.lat.toFixed(5)
                                : ""
                        }
                        className="form-control rounded-0"
                    />
                </div>
                <div className="mb-3 col-12 col-sm-6 ps-sm-1 col-lg-2 ps-lg-1 pe-lg-1">
                    <label className="fw-500">
                        <Trans>Longitude</Trans>:
                    </label>
                    <Input
                        type="text"
                        disabled={true}
                        value={
                            locationCoordinates?.lng
                                ? locationCoordinates?.lng.toFixed(5)
                                : ""
                        }
                        className="form-control rounded-0"
                    />
                </div>
                <div className="align-self-end mb-3 col-12 col-lg-3 ps-lg-1 pe-lg-0">
                    <button
                        className="btn btn-outline-primary fw-600 rounded-0 w-100"
                        type="submit"
                    >
                        <Trans>Add Location</Trans>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default CreateLocationForm
