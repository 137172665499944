import React from "react"
import Pagination from "rc-pagination"
import { useUsers } from "../../../providers/UsersProvider"
import EllipsisLoader from "../../template/EllipsisLoader"
import NoData from "../../template/NoData"
import Input from "../../template/Input"
import formatDateTimeValue from "../../../functions/formatDateTimeValue"
import formatDateValue from "../../../functions/formatDateValue"
import { Trans } from "react-i18next"

const ArchivedUsersTable = () => {
    const { perPage, loading, items, totalItems, currentPage, setCurrentPage } =
        useUsers()

    return (
        <div className="w-100">
            <div className="w-100 bg-light">
                <table className="table align-middle overflow-hidden rounded-top">
                    <thead className="bg-white table-primary text-white">
                        <tr>
                            <th>
                                <Trans>Phone Number</Trans>
                            </th>
                            <th>
                                <Trans>Gender</Trans>
                            </th>
                            <th>
                                <Trans>Birth Date</Trans>
                            </th>
                            <th>
                                <Trans>Last Successful Login</Trans>
                            </th>
                        </tr>
                    </thead>
                    {loading ? (
                        <tr style={{ height: `${perPage * 50}px` }}>
                            <td colSpan={4}>
                                <EllipsisLoader dark />
                            </td>
                        </tr>
                    ) : (
                        <tbody>
                            {(!items || items?.length === 0) && (
                                <tr className="text-center">
                                    <td className="fw-600" colSpan={4}>
                                        <div className="w-25 mx-auto">
                                            <NoData />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {items?.length > 0 &&
                                items.map((item, index) => {
                                    return (
                                        <tr key={index} className="bg-white">
                                            <td>
                                                <Input
                                                    type="text"
                                                    value={item.phone_number}
                                                    mask="(999) 999 99 99"
                                                    className="table-field form-control border-0"
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                <Trans>{item.gender}</Trans>
                                            </td>
                                            <td>
                                                {item?.birth_date &&
                                                    formatDateValue(
                                                        new Date(
                                                            item?.birth_date
                                                        )
                                                    )}
                                            </td>
                                            <td>
                                                {item?.last_successful_login &&
                                                    formatDateTimeValue(
                                                        new Date(
                                                            item?.last_successful_login
                                                        )
                                                    )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            {items?.length > 0 &&
                                items.length !== perPage &&
                                Array.from(
                                    Array(perPage - items.length).keys()
                                ).map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="invisible border-1 border-light bg-light h-50px"
                                        >
                                            <td className="fw-bold" colSpan={1}>
                                                Data
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    )}
                </table>
            </div>
            {items?.length > 0 && (
                <div className="mt-3 text-end">
                    <Pagination
                        onChange={page => setCurrentPage(page)}
                        current={currentPage}
                        pageSize={perPage}
                        total={totalItems}
                    />
                </div>
            )}
        </div>
    )
}

export default ArchivedUsersTable
