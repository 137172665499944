import { axiosRequest } from "../axiosRequest"

export const AdminController = {
    fetch: async ({ size, page }) =>
        await axiosRequest({
            url: "/admin/fetch",
            method: "post",
            body: { size, page },
            isProtected: true,
        }),
    fetchCurrent: async () =>
        await axiosRequest({
            url: "/admin/fetch-current",
            method: "post",
            isProtected: true,
        }),
    register: async ({ email, password }) =>
        await axiosRequest({
            url: "/admin/register",
            method: "post",
            body: { email, password },
            isProtected: true,
        }),
    update: async ({ token, email, password }) =>
        await axiosRequest({
            url: "/admin/update",
            method: "post",
            body: { token, email, password },
            isProtected: true,
        }),
    delete: async ({ token }) =>
        await axiosRequest({
            url: "/admin/delete",
            method: "post",
            body: { token },
            isProtected: true,
        }),
    login: async ({ email, password }) =>
        await axiosRequest({
            url: "/admin/login",
            method: "post",
            body: { email, password },
        }),
}
