import React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import { Trans } from "react-i18next"

const TableButton = ({
    onClick = () => {},
    disabled = false,
    tooltip = "",
    icon = null,
    inline = true,
    children,
}) => {
    const renderTooltip = props => (
        <Tooltip {...props}>
            <Trans>{tooltip}</Trans>
        </Tooltip>
    )

    if (tooltip)
        return (
            <OverlayTrigger placement="left" overlay={renderTooltip}>
                <div>
                    <button
                        className={`btn btn-sm fw-600 border-0 rounded-0 d-flex align-items-center gap-2 w-100 ${
                            inline ? "px-2 py-2" : "px-3 py-2"
                        }`}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <div className="crud-icon">{icon}</div>
                        <div className="mt-1px">{children}</div>
                    </button>
                </div>
            </OverlayTrigger>
        )

    if (!tooltip)
        return (
            <div>
                <button
                    className={`btn btn-sm fw-600 border-0 rounded-0 d-flex align-items-center gap-2 w-100 ${
                        inline ? "px-2 py-2" : "px-3 py-2"
                    }`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    <div className="crud-icon">{icon}</div>
                    <div className="mt-1px">{children}</div>
                </button>
            </div>
        )
}

export default TableButton
