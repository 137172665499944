import React from "react"
import Layout from "../components/template/Layout"
import RouterLinks from "../components/page-components/dashboard/router-links/RouterLinks"
import ActiveCampaignsTable from "../components/page-components/dashboard/active-campaigns-table/ActiveCampaignsTable"

const Home = () => {
    return (
        <Layout>
            <div className="p-3">
                <ActiveCampaignsTable />
                <RouterLinks />
            </div>
        </Layout>
    )
}

export default Home
