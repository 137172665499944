import React from "react"
import Layout from "../components/template/Layout"
import FiltersTable from "../components/page-components/filters/FiltersTable"
import ArchivedFiltersTable from "../components/page-components/filters/ArchivedFiltersTable"
import { useFilters } from "../providers/FiltersProvider"
import EditFilterModal from "../components/page-components/filters/EditFilterModal"
import CreateFilterModal from "../components/page-components/filters/CreateFilterModal"
import ArchiveFilterModal from "../components/page-components/filters/ArchiveFilterModal"
import { Icons } from "../utilities/Icons"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { TableViewTypes } from "../providers/AppProvider"
import { Trans, useTranslation } from "react-i18next"

const Filters = () => {
    const { t } = useTranslation()

    const {
        isEditModalOpen,
        isCreateModalOpen,
        isArchiveModalOpen,
        setIsCreateModalOpen,
        setTableView,
        loading,
        tableView,
    } = useFilters()

    const disabledMainTable = tableView === TableViewTypes.archived && loading
    const disabledArchivedTable = tableView === TableViewTypes.main && loading

    const openCreateModal = () => setIsCreateModalOpen(true)

    return (
        <Layout>
            <div className="p-4">
                <button
                    className="btn btn-primary mb-3 d-flex align-items-center"
                    onClick={openCreateModal}
                >
                    <div className="small-icon">{Icons.add()}</div>
                    <div>
                        <Trans>Add Filter</Trans>
                    </div>
                </button>

                {isEditModalOpen && <EditFilterModal />}
                {isCreateModalOpen && <CreateFilterModal />}
                {isArchiveModalOpen && <ArchiveFilterModal />}

                <Tabs
                    defaultActiveKey="main"
                    className="fw-bold mb-3 table-tabs"
                    onSelect={eventKey => {
                        setTableView(eventKey)
                    }}
                    fill
                >
                    <Tab
                        eventKey={TableViewTypes.main}
                        title={t("Main")}
                        disabled={disabledMainTable}
                    >
                        <FiltersTable />
                    </Tab>
                    <Tab
                        eventKey={TableViewTypes.archived}
                        title={t("Archived")}
                        disabled={disabledArchivedTable}
                    >
                        <ArchivedFiltersTable />
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    )
}

export default Filters
