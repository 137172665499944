import React from "react"
import { useCampaignOwnerUsers } from "./CampaignOwnerUsersProvider"
import { Icons } from "../../../../utilities/Icons"
import { Trans } from "react-i18next"

const DeleteCampaignOwnerUserFrom = ({ user }) => {
    const { onSubmitDeleteButtonClick, resetTable } = useCampaignOwnerUsers()

    return (
        <>
            <td className="fw-bold text-primary" colSpan={2}>
                <Trans>Are you sure you want to remove user?</Trans>
            </td>
            <td>
                <div className="d-flex flex-column flex-lg-row justify-content-lg-end gap-lg-1">
                    <button
                        className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-1 custom-user-button"
                        type="submit"
                        id={user.token}
                        onClick={onSubmitDeleteButtonClick}
                    >
                        <div className="crud-icon">{Icons.check()}</div>
                        <div className="mt-1px">
                            <Trans>Submit</Trans>
                        </div>
                    </button>
                    <button
                        className="btn btn-sm fw-600 rounded-0 d-flex align-items-center gap-1 custom-user-button"
                        type="button"
                        onClick={resetTable}
                    >
                        <div className="crud-icon">{Icons.close()}</div>
                        <div className="mt-1px">
                            <Trans>Cancel</Trans>
                        </div>
                    </button>
                </div>
            </td>
        </>
    )
}

export default DeleteCampaignOwnerUserFrom
