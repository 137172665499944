import { axiosRequest } from "../axiosRequest"

export const ColorController = {
    fetch: async () =>
        await axiosRequest({
            url: "/color/fetch",
            method: "post",
            isProtected: true,
        }),
}
