import React from "react"
import { useState } from "react"
import { Icons } from "../../utilities/Icons"

const PasswordContainer = ({ value }) => {
    // States and Hooks
    const [showPassword, setShowPassword] = useState(false)

    // Methods
    const onTogglePasswordClick = e => {
        e.preventDefault()
        setShowPassword(!showPassword)
    }

    // Render
    return (
        <div className="d-flex align-items-center">
            <input
                type={showPassword ? "text" : "password"}
                className="password-container p-0 form-control border-0"
                value={showPassword ? value : "********************"}
                disabled
            />
            <button
                type="button"
                className="btn rounded-0 p-0"
                onClick={onTogglePasswordClick}
            >
                <div className="crud-icon">
                    {showPassword
                        ? Icons.closePassword()
                        : Icons.showPassword()}
                </div>
            </button>
        </div>
    )
}

export default PasswordContainer
