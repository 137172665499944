import * as xlsx from "xlsx"

export const getCodesFromFile = ({ file, hasHeaders = false }) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)

        reader.onload = () => {
            const data = new Uint8Array(reader.result)
            const workbook = xlsx.read(data, { type: "array" })
            const sheetName = workbook.SheetNames[0]
            const sheetData = xlsx.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
            )

            const codes = sheetData.map(item => String(item[0]))

            if (hasHeaders) {
                codes.shift()
                return resolve(codes)
            }

            return resolve(codes)
        }

        reader.onerror = error => reject(error)
    })
