import React, { createContext, useEffect, useState } from "react"
import { useContext } from "react"
import { useApi } from "./ApiProvider"
import { toast } from "react-toastify"
import { TableViewTypes } from "./AppProvider"

const CampaignOwnersContext = createContext()
export const useCampaignOwners = () => useContext(CampaignOwnersContext)

const CampaignOwnersProvider = ({ children }) => {
    // States and Hooks
    const { campaignOwner } = useApi()
    const perPage = 7
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isUsersModalOpen, setIsUsersModalOpen] = useState(false)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
    const [tableView, setTableView] = useState(TableViewTypes.main)

    // Methods
    const fetchCampaignOwners = async () => {
        const response = await campaignOwner.fetch({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        setItems(response.data.rows)
    }

    const fetchArchivedCampaignOwners = async () => {
        const response = await campaignOwner.fetchArchived({
            size: perPage,
            page: currentPage,
        })
        setTotalItems(response.data.count)
        setItems(response.data.rows)
    }

    const initialize = async () => {
        setLoading(true)
        if (tableView === TableViewTypes.main) await fetchCampaignOwners()
        if (tableView === TableViewTypes.archived)
            await fetchArchivedCampaignOwners()
        setLoading(false)
    }

    const activateCampaignOwner = async token => {
        const { data, error } = await campaignOwner.activate({ token })
        if (error) {
            toast.error("Network Error")
            await initialize()
        }
        if (data) {
            const newItems = items.map(item => {
                if (item.token === data.token) return data
                return item
            })
            return setItems(newItems)
        }
    }

    const disableCampaignOwner = async token => {
        const { data, error } = await campaignOwner.disable({ token })
        if (error) {
            toast.error("Network Error")
            await initialize()
        }
        if (data) {
            const newItems = items.map(item => {
                if (item.token === data.token) return data
                return item
            })
            return setItems(newItems)
        }
    }

    // UseEffect
    useEffect(() => {
        initialize()
    }, [])

    useEffect(() => {
        setCurrentPage(1)
        initialize()
    }, [tableView])

    useEffect(() => {
        initialize()
    }, [currentPage])

    // Binding
    const valueObject = {
        // States
        perPage,

        loading,
        setLoading,

        items,
        setItems,

        currentPage,
        setCurrentPage,

        totalItems,
        setTotalItems,

        selectedItem,
        setSelectedItem,

        isEditModalOpen,
        setIsEditModalOpen,

        isCreateModalOpen,
        setIsCreateModalOpen,

        isUsersModalOpen,
        setIsUsersModalOpen,

        isArchiveModalOpen,
        setIsArchiveModalOpen,

        tableView,
        setTableView,

        // Methods
        fetchCampaignOwners,
        fetchArchivedCampaignOwners,
        initialize,
        activateCampaignOwner,
        disableCampaignOwner,
    }

    // Render
    return (
        <CampaignOwnersContext.Provider value={valueObject}>
            {children}
        </CampaignOwnersContext.Provider>
    )
}

export default CampaignOwnersProvider
